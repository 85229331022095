import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardItem, Modal, ModalOpenButton } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Formik } from "formik";
import { uniqBy, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import {
  AttachmentSnapshot,
  ItemAttachment,
  QuotePdfCalculationType,
} from "../../../../clients/graphqlTypes";
import {
  CrmSendEmailForm,
  FormValues,
  UrlAttachment,
  useCrmSendEmail,
} from "../../crm/CrmSendEmailForm";
import { crmContactToEmailRecipient } from "../../crm/utils";
import { useAllProjectContacts } from "../../projects/useAllProjectContacts";
import { useProjectContracteeInvite } from "../../projects/useProjectContracteeInvite";
import { QuotePreviewListItem } from "../QuotePreviewListItem";
import {
  useGenerateQuoteContractingPdfLazyQuery,
  useGenerateQuotePdfLazyQuery,
} from "../buttons/DownloadQuoteAsPdfIconButton.generated";
import { InviteContracteeField } from "../form-fields/InviteContracteeField";
import { PdfTemplateRadioGroup } from "./PdfTemplateSelectModal";
import { QuotePublishingInfoModal } from "./QuotePublishingInfoModal";
import {
  SendQuoteViaEmailModal_ProjectFragment,
  SendQuoteViaEmailModal_QuoteFragment,
  useSendQuoteViaEmailQuery,
} from "./SendQuoteViaEmailModal.generated";
import { AttachmentInput } from "@msys/attachment";

interface Props {
  quoteId: string;
  quote: SendQuoteViaEmailModal_QuoteFragment;
  projectId: string;
  project: SendQuoteViaEmailModal_ProjectFragment;
  calculationType: QuotePdfCalculationType;
  pdfTemplateId?: string | null;
  handleClose: () => void;
  handleComplete?: (handleClose: () => void) => Promise<void>;
}

export const SendQuoteViaEmailModal = ({
  quoteId,
  quote,
  projectId,
  project,
  handleClose,
  handleComplete,
  calculationType,
  pdfTemplateId: passedPdfTemplateId,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["QuoteSend"]);

  const {
    recipientOptions,
    canSelectPdfTemplate,
    organisationQuoteTemplates,
    urlAttachments,
    getAttachmentsOnSubmit,
    query,
    pdfTemplateId,
    setPdfTemplateId,
  } = useSendQuoteViaEmail({
    projectId,
    quoteId,
    calculationType,
    pdfTemplateId: passedPdfTemplateId,
  });

  const context = React.useMemo(
    () => ({ type: "QUOTE", id: quoteId }) as const,
    [quoteId]
  );

  const { initialValues, validationSchema, handleSendEmail, emailTemplates } =
    useCrmSendEmail({
      urlAttachments,
      getAttachmentsOnSubmit,
      emailTemplateContext: "QUOTE",
      context,
    });

  const { canInviteContractee, handleInviteContractee } =
    useProjectContracteeInvite(project);

  const handleSubmit = async (
    values: FormValues & { inviteContractee: boolean }
  ) => {
    await handleSendEmail(values);
    enqueueSnackbar(
      t("Quote was sent to client", {
        ns: "QuoteSend",
      })
    );

    if (values.inviteContractee) {
      if (!values.recipient?.crmPersonId) throw new Error("CrmPerson missing");

      await handleInviteContractee(values.recipient.crmPersonId);
    }

    if (handleComplete) {
      await handleComplete(handleClose);
    } else {
      handleClose();
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues, inviteContractee: false }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formikProps => (
        <Modal
          title={t("Send quote to client", {
            ns: "QuoteSend",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Send", { ns: "Global" }),
              buttonProps: {
                loading: formikProps.isSubmitting,
                type: "submit",
                form: formId,
              },
            },
          ]}
          isLoading={query.loading}
          maxWidth="md"
        >
          <Stack spacing={2}>
            <CardItem>
              <QuotePreviewListItem
                quote={quote}
                Action={
                  <ModalOpenButton
                    Modal={QuotePublishingInfoModal}
                    modalProps={{ projectId, quoteId }}
                  >
                    <IconButton size="small" color="primary">
                      <Tooltip title={t("Edit", { ns: "Global" })}>
                        <EditIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  </ModalOpenButton>
                }
              />
            </CardItem>

            <CrmSendEmailForm
              recipientOptions={recipientOptions}
              additionalFields={
                canSelectPdfTemplate ? (
                  <Box mt={1} mb={-1}>
                    <PdfTemplateRadioGroup
                      label={t("Choose PDF template", { ns: "QuoteSend" })}
                      organisationQuoteTemplates={organisationQuoteTemplates}
                      value={pdfTemplateId}
                      onChange={setPdfTemplateId}
                    />
                  </Box>
                ) : undefined
              }
              formId={formId}
              availableTemplates={emailTemplates}
              context={context}
            />

            {canInviteContractee && (
              <InviteContracteeField
                project={project}
                checked={formikProps.values.inviteContractee}
                handleChange={checked => {
                  formikProps.setFieldValue("inviteContractee", checked);
                }}
                disabled={!formikProps.values.recipient?.crmPersonId}
              />
            )}
          </Stack>
        </Modal>
      )}
    </Formik>
  );
};

export function useSendQuoteViaEmail({
  projectId,
  quoteId,
  calculationType,
  pdfTemplateId: passedPdfTemplateId,
}: {
  projectId: string;
  quoteId: string;
  calculationType: QuotePdfCalculationType;
  pdfTemplateId?: string | null;
}) {
  const { t } = useTranslate(["QuoteSend"]);

  const [pdfTemplateId, setPdfTemplateId] = React.useState<string | null>(
    passedPdfTemplateId ?? null
  );

  const { getQuotePdf } = useQuotePdf(projectId, quoteId, calculationType);

  const { contacts: projectContacts } = useAllProjectContacts(projectId);

  const client = useApolloClient();
  const query = useSendQuoteViaEmailQuery({
    client,
    variables: {
      projectId,
      quoteId,
    },
    fetchPolicy: "no-cache",
  });

  const quote = getDataOrNull((query.data ?? query.previousData)?.quote)?.quote;
  const contractee = quote?.contractee;
  const quoteContacts = contractee
    ? [
        ...(contractee.viewerCrmCompany ? [contractee.viewerCrmCompany] : []),
        ...(contractee.viewerCrmCompany?.members ?? []),
      ]
    : [];

  const recipientOptions = uniqBy(
    [...quoteContacts, ...projectContacts].filter(
      contact => contact.id && contact.email
    ),
    contact => contact.id
  ).map(crmContactToEmailRecipient);

  const quoteAttachments = React.useMemo(
    () =>
      quote?.items
        .filter(item => item.isVisibleToOtherSide)
        .flatMap(
          item =>
            item.attachments.filter(
              attachment =>
                attachment.clientVisibility &&
                (item.isRootItem || attachment.mimeType === "application/pdf")
            ) ?? []
        ) ?? [],
    [quote?.items]
  );

  const organisationQuoteTemplates =
    getDataOrNull(
      (query.data ?? query.previousData)?.organisationContractingPdfs
    )?.contractingPdfs ?? [];

  const canSelectPdfTemplate = organisationQuoteTemplates.length > 0;

  if (!query.loading && !contractee)
    throw new Error("Crm organisation is missing");
  if (!query.loading && !quote) throw new Error("Quote is missing");

  const urlAttachments = React.useMemo(() => {
    const urlAttachments: UrlAttachment[] = [];

    urlAttachments.push(
      ...quoteAttachments.map(quoteAttachment => ({
        attachment: convertAttachmentSnapshotToUrlAttachment(quoteAttachment),
        group: t("Files from quote", {
          ns: "QuoteSend",
        }),
      }))
    );

    return urlAttachments;
  }, [t, quoteAttachments]);

  const getAttachmentsOnSubmit = async (): Promise<Array<AttachmentInput>> => {
    const url = await getQuotePdf(pdfTemplateId);
    return [{ url, mimeType: "application/pdf", title: "" }];
  };

  return {
    recipientOptions,
    canSelectPdfTemplate,
    organisationQuoteTemplates,
    urlAttachments,
    getAttachmentsOnSubmit,
    query,
    quote,
    getQuotePdf,
    pdfTemplateId,
    setPdfTemplateId,
  };
}

function convertAttachmentSnapshotToUrlAttachment(
  attachment: AttachmentSnapshot | ItemAttachment
): UrlAttachment["attachment"] {
  return {
    filename: attachment.title,
    mimeType: attachment.mimeType,
    url: attachment.url,
  };
}

function useQuotePdf(
  projectId: string,
  quoteId: string,
  calculationType: QuotePdfCalculationType
) {
  const client = useApolloClient();
  const [generateQuotePdf, quotePdfQuery] = useGenerateQuotePdfLazyQuery({
    client,
  });
  const [generateQuoteTemplatePdf, quoteTemplatePdfQuery] =
    useGenerateQuoteContractingPdfLazyQuery({ client });

  const getQuotePdf = React.useCallback(
    async (pdfTemplateId: string | null): Promise<string> => {
      if (pdfTemplateId) {
        const { data } = await generateQuoteTemplatePdf({
          variables: {
            projectId,
            quoteId,
            calculationType,
            contractingPdfId: pdfTemplateId,
          },
        });
        const url = getDataOrNull(data?.quoteGenerateContractingPdf)?.url;
        if (!url) throw new Error("Failed to generate PDF");

        return url;
      } else {
        const { data } = await generateQuotePdf({
          variables: {
            projectId,
            quoteId,
            calculationType,
          },
        });
        const url = getDataOrNull(data?.quoteGeneratePdf)?.url;
        if (!url) throw new Error("Failed to generate PDF");

        return url;
      }
    },
    [
      generateQuotePdf,
      projectId,
      quoteId,
      calculationType,
      generateQuoteTemplatePdf,
    ]
  );

  return {
    getQuotePdf,
    loading: quotePdfQuery.loading || quoteTemplatePdfQuery.loading,
  };
}
