import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DataGrid,
  GridColDef,
  ListHeader,
  ModalOpenButton,
  TagChips,
  useFormatting,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, IconButton, Stack } from "@mui/material";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-premium";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { ButtonCreate } from "../../../commons/button/Button";
import { Page, PageTopbarItem } from "../../../commons/layout/Page";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal";
import { SearchItemInputModal } from "../../../features/doc-items/SearchItemInputModal";
import { GetTemplateListCodeModal } from "../../../features/templateLists/GetTemplateListCodeModal";
import { useDataGridStateStore } from "../../../features/users/useDataGridStateStore";
import { buildDocPath } from "../../../utils";
import {
  TemplateListOverview_TemplateFragment,
  useAddTemplatesToTemplateListMutation,
  useRemoveTemplatesFromTemplateListMutation,
  useSetTemplatesOrderInTemplateListMutation,
  useTemplateListOverviewQuery,
} from "./TemplateListOverview.generated";
import { TemplateListOverviewSubHeader } from "./TemplateListOverviewSubHeader";

interface Props {
  submenuItems: PageTopbarItem[];
  pathToDocList: string;
  pathToTemplatesDocList: string;
}

export const TemplateListOverview = ({
  submenuItems,
  pathToDocList,
  pathToTemplatesDocList,
}: Props) => {
  const { id } = useParams();
  if (!id) throw new Error("TemplateList id is missing");

  const { t } = useTranslate(["TemplateLists", "Templates"]);

  const client = useApolloClient();
  const query = useTemplateListOverviewQuery({
    client,
    variables: { id: id },
  });

  const [addTemplates, { loading: addTemplatesLoading }] =
    useAddTemplatesToTemplateListMutation({ client });

  const templateList = getDataOrNull(query.data?.templateList);

  const breadcrumbs = React.useMemo(
    () =>
      templateList
        ? [
            {
              title: t("Template lists", { ns: "Templates" }),
              to: pathToDocList,
            },
            {
              title: templateList.title,
              to: `/${pathToDocList}/${templateList.id}`,
            },
          ]
        : undefined,
    [pathToDocList, t, templateList]
  );

  return (
    <Page
      title={templateList?.title ?? ""}
      subtitle={t("Template lists", { ns: "Templates" })}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      subHeader={
        templateList ? (
          <TemplateListOverviewSubHeader
            templateList={templateList}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
            pathToDocList={pathToDocList}
          />
        ) : undefined
      }
      // action={
      //   <>
      //     <ModalOpenButton
      //       Modal={GetTemplateListCodeModal}
      //       modalProps={{ templateListId: id }}
      //     >
      //       <Button
      //         color="primary"
      //         variant="contained"
      //         onClick={e => {
      //           e.preventDefault();
      //           e.stopPropagation();
      //         }}
      //       >
      //         {t("Get list code", { ns: "TemplateLists" })}
      //       </Button>
      //     </ModalOpenButton>
      //   </>
      // }
    >
      {templateList && (
        <PageContainer>
          <ListHeader
            CreateButton={
              templateList ? (
                <ModalOpenButton
                  Modal={SearchItemInputModal}
                  modalProps={{
                    initialSearchTab: "templates",
                    initialSearchValue: "",
                    allowedSearchTabs: ["templates"],
                    excludeTemplateIds: templateList.templates.map(t => t.id),
                    handleSelect: async ({ templates }) => {
                      await addTemplates({
                        variables: {
                          input: {
                            templateListId: templateList.id,
                            templateIds: templates.map(
                              template => template.template.id
                            ),
                          },
                        },
                      });
                    },
                    showQuantityInput: false,
                    AddToListIcon: AddIcon,
                  }}
                >
                  <ButtonCreate
                    title={t("Add templates", { ns: "TemplateLists" })}
                    disabled={addTemplatesLoading}
                  />
                </ModalOpenButton>
              ) : undefined
            }
            mb={2}
          />
          <TemplateQuotesList
            items={templateList.templates}
            loading={query.loading}
            pathToDocList={pathToTemplatesDocList}
            templateListId={templateList.id}
          />
        </PageContainer>
      )}
    </Page>
  );
};

function TemplateQuotesList({
  items,
  loading,
  pathToDocList,
  templateListId,
}: {
  items: TemplateListOverview_TemplateFragment[];
  loading: boolean;
  pathToDocList: string;
  templateListId: string;
}) {
  const navigate = useNavigate();

  const { t } = useTranslate(["Templates", "TemplateLists", "Global"]);
  const { getFormattedDate, getFormattedPrice } = useFormatting();

  const stateStore = useDataGridStateStore(
    "TemplateListOverview-TemplateQuotesList"
  );

  const client = useApolloClient();

  const [removeTemplates, { loading: removeTemplatesLoading }] =
    useRemoveTemplatesFromTemplateListMutation({ client });

  const [setTemplatesOrder, { loading: setOrderLoading }] =
    useSetTemplatesOrderInTemplateListMutation({ client });

  const quoteTemplateColumns = React.useMemo(
    (): GridColDef<TemplateListOverview_TemplateFragment>[] => [
      {
        field: "title",
        headerName: t("Title", { ns: "Templates" }),
        flex: 3,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: template }) => template.title,
      },
      {
        field: "implementsTemplateType",
        headerName: t("Template type", { ns: "Templates" }),
        flex: 1,
        minWidth: 50,
        sortable: false,
        renderCell: ({ row: template }) =>
          template.implementsTemplateType?.templateType?.title ?? "",
      },
      {
        field: "tags",
        headerName: t("Tags", { ns: "Templates" }),
        flex: 1.5,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: template }) => (
          <TagChips tags={template.customTags} wrap={false} />
        ),
      },
      {
        field: "discountedPrice",
        headerName: t("Price", { ns: "Templates" }),
        width: 120,
        headerAlign: "right",
        align: "right",
        sortable: false,
        renderCell: ({ row: template }) =>
          getFormattedPrice(template.proposedCalculation?.priceNetTotal || 0),
      },
      {
        field: "createdAt",
        headerName: t("Created", { ns: "Templates" }),
        width: 120,
        sortable: false,
        renderCell: ({ row: template }) => getFormattedDate(template.createdAt),
      },
      {
        field: "actions",
        headerName: "",
        filterable: false,
        sortable: false,
        width: 40,
        renderCell: ({ row: template }) => (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
              <ModalOpenButton
                Modal={ConfirmModal}
                modalProps={{
                  title: t(
                    "Are you sure you want to remove this template from the list?",
                    { ns: "TemplateLists" }
                  ),
                  handleConfirm: async () => {
                    await removeTemplates({
                      variables: {
                        input: {
                          templateListId: templateListId,
                          templateIds: [template.id],
                        },
                      },
                    });
                  },
                }}
              >
                <IconButton
                  size="small"
                  color={"primary"}
                  sx={{ marginLeft: "-10px" }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </ModalOpenButton>
            </RestrictedByOrganisationPermissionWithDebug>
          </Stack>
        ),
      },
    ],
    [t, getFormattedPrice, getFormattedDate, removeTemplates, templateListId]
  );

  const handleRowOrderChange = React.useCallback(
    async (params: GridRowOrderChangeParams) => {
      const itemsCloned = [...items];
      const item = itemsCloned.splice(params.oldIndex, 1)[0];
      itemsCloned.splice(params.targetIndex, 0, item);
      await setTemplatesOrder({
        variables: {
          input: {
            templateListId,
            templateIds: itemsCloned.map(item => item.id),
          },
        },
      });
    },
    [items, setTemplatesOrder, templateListId]
  );

  const itemsWithReorder = React.useMemo(
    () => items.map(i => ({ ...i, __reorder__: i.title })),
    [items]
  );

  return (
    <DataGrid<TemplateListOverview_TemplateFragment>
      stateStore={stateStore}
      loading={loading || setOrderLoading || removeTemplatesLoading}
      hideFooter={true}
      columns={quoteTemplateColumns}
      rows={itemsWithReorder}
      getRowId={row => row.id}
      paginationMode="client"
      sortingMode="client"
      disableRowSelectionOnClick
      pagination={false}
      onRowClick={({ row: template }) => {
        return navigate(
          buildDocPath(
            `${pathToDocList}/${template.id}`,
            template.resolvedAsReadModelVersionNumber ?? null
          )
        );
      }}
      localeText={{
        noRowsLabel: t("There are no items to display", {
          ns: "Global",
        }),
        MuiTablePagination: {
          labelRowsPerPage: t("Rows per page", {
            ns: "Global",
          }),
        },
      }}
      rowReordering
      onRowOrderChange={handleRowOrderChange}
    />
  );
}
