import { draftStateToHtml } from "@msys/textutils";
import {
  CollapseSection,
  Modal,
  ModalOpenButton,
  RichTextEditor,
} from "@msys/ui";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { RawDraftContentState, convertFromRaw } from "draft-js";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { ItemType } from "../../../clients/graphqlTypes";
import { cleanHTML } from "../../utils";
import { ProductSearchModal } from "../products/modals/ProductSearchModal";
import { TemplateTypesSearchModal } from "../templateTypes/TemplateTypesSearchModal";
import { TemplateTypeSelect } from "../templateTypes/TemplateTypeSelect";

export interface FormValues {
  title: string;
  rootItemType: ItemType;
  description: string;
  product: { articleNumber: string; supplierId: string } | null;
  templateType: { id: string; title: string } | null;
}

interface Props {
  title?: string;
  templateTitle?: string;
  documentItemTypes: ItemType[];
  handleClose: () => void;
  handleComplete: (values: FormValues) => Promise<void> | void;
  showProductSelect?: boolean;
  showTemplateTypeSelect?: boolean;
  initialTemplateTypeId?: string;
}

export const CreateQuoteTemplateModal = ({
  title,
  templateTitle = "",
  documentItemTypes,
  handleClose,
  handleComplete,
  showProductSelect = false,
  showTemplateTypeSelect = false,
  initialTemplateTypeId,
}: Props) => {
  const { t } = useTranslate(["QuoteCreate", "QuoteItem", "Global"]);

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(
        t("Title", {
          ns: "Global",
        })
      )
      .required(),
    rootItemType: Yup.string()
      .label(
        t("Top-level item type", {
          ns: "QuoteCreate",
        })
      )
      .required(),
    description: Yup.string().label(
      t("Description", {
        ns: "QuoteItem",
      })
    ),
    templateType: Yup.object({
      id: Yup.string().required(),
      title: Yup.string(),
    }).nullable(),
  });

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: templateTitle || "",
      rootItemType: documentItemTypes[0]!,
      description: "",
      product: null,
      templateType: initialTemplateTypeId
        ? { id: initialTemplateTypeId, title: "" }
        : null,
    }),
    [documentItemTypes, templateTitle, initialTemplateTypeId]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleComplete}
    >
      {(formikProps: FormikProps<FormValues>) => {
        return (
          <Modal
            title={
              title ??
              t("New Quote Template", {
                ns: "QuoteCreate",
              })
            }
            dialogProps={{ maxWidth: "xs" }}
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: {
                  variant: "text",
                  disabled: formikProps.isSubmitting,
                },
              },
              {
                label: t("Create Template", {
                  ns: "QuoteCreate",
                }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  loading: formikProps.isSubmitting,
                  disabled: formikProps.isSubmitting || !formikProps.isValid,
                },
              },
            ]}
            dialogActions={
              showProductSelect &&
              formikProps.values.rootItemType === "paid" ? (
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="text"
                    color="primary"
                    disabled={formikProps.isSubmitting}
                  >
                    {t("Cancel", {
                      ns: "Global",
                    })}
                  </Button>

                  <ModalOpenButton
                    Modal={ProductSearchModal}
                    modalProps={{
                      cancelTitle: t("Skip", {
                        ns: "Global",
                      }),
                      handleCancel: formikProps.submitForm,
                      handleProductChoice: async product => {
                        formikProps.setFieldValue("product", {
                          articleNumber: product.articleNumber,
                          supplierId: product.supplierId,
                        });
                        await formikProps.submitForm();
                      },
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      loading={formikProps.isSubmitting}
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    >
                      {t("Select Product", {
                        ns: "QuoteCreate",
                      })}
                    </LoadingButton>
                  </ModalOpenButton>
                </DialogActions>
              ) : undefined
            }
            handleClose={handleClose}
          >
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                <Field
                  label={t("Title", {
                    ns: "Global",
                  })}
                  name="title"
                  component={TextField}
                  required
                  autoFocus
                />

                <CollapseSection
                  title={t("Additional information", {
                    ns: "QuoteCreate",
                  })}
                  isInitiallyExpanded={true}
                >
                  <Stack direction="column" spacing={1}>
                    <RichTextEditor
                      label={t("Description", {
                        ns: "QuoteItem",
                      })}
                      htmlContent={formikProps.values.description}
                      onChange={async (content: RawDraftContentState) => {
                        formikProps.setFieldValue(
                          "description",
                          cleanHTML(draftStateToHtml(convertFromRaw(content)))
                        );
                      }}
                      saveButtonLabel={t("Save", {
                        ns: "Global",
                      })}
                      cancelButtonLabel={t("Cancel", {
                        ns: "Global",
                      })}
                    />
                    {showTemplateTypeSelect && (
                      <TemplateTypeSelect
                        templateTypeId={
                          formikProps.values.templateType?.id ?? null
                        }
                        onChange={templateType => {
                          formikProps.setValues(values => {
                            return {
                              ...values,
                              templateType: templateType,
                              title: values.title || templateType?.title || "",
                            };
                          });
                        }}
                        required={false}
                      />
                    )}
                  </Stack>
                </CollapseSection>
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export interface CreateQuoteTemplateProcessRef {
  createQuoteTemplate: (templateTitle?: string) => Promise<FormValues | null>;
}

export const CreateQuoteTemplateProcess = React.forwardRef(
  (
    props: Omit<Props, "handleClose" | "handleComplete">,
    forwardedRef: React.Ref<CreateQuoteTemplateProcessRef>
  ) => {
    const [state, setState] = React.useState<{
      isOpen: boolean;
      templateTitle: string | null;
    }>({ isOpen: false, templateTitle: null });

    const resolveRef = React.useRef<
      ((values: FormValues | null) => void) | null
    >(null);

    React.useImperativeHandle(
      forwardedRef,
      () => ({
        createQuoteTemplate: (templateTitle?: string) =>
          new Promise<FormValues | null>(resolve => {
            resolveRef.current = resolve;
            setState({ isOpen: true, templateTitle: templateTitle ?? null });
          }),
      }),
      []
    );

    return state.isOpen ? (
      <CreateQuoteTemplateModal
        handleClose={() => {
          resolveRef.current?.(null);
          resolveRef.current = null;
          setState({ isOpen: false, templateTitle: null });
        }}
        handleComplete={values => {
          resolveRef.current?.(values);
          resolveRef.current = null;
          setState({ isOpen: false, templateTitle: null });
        }}
        {...props}
        templateTitle={state.templateTitle ?? props.templateTitle}
      />
    ) : null;
  }
);
