import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import GetAppIcon from "@mui/icons-material/GetApp";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useOpenFile } from "../../../commons/hooks/useOpenFile";
import { Attachment } from "../../../../clients/graphqlTypes";
import {
  GeneratePurchaseOrderPdfDocument,
  GeneratePurchaseOrderPdfQuery,
  GeneratePurchaseOrderPdfQueryVariables,
} from "./PurchaseOrderDownloadButton.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  size?: "small" | "medium";
  projectId: string;
  orderId: string;
  orderFile?: Pick<Attachment, "id" | "url"> | null;
}

export const PurchaseOrderDownloadButton = ({
  size = "small",
  projectId,
  orderId,
  orderFile,
}: Props) => {
  const { t } = useTranslate("PurchaseOrders");
  const { enqueueSnackbar } = useSnackbar();
  const { openPdf } = useOpenFile();

  const client = useApolloClient();

  const onClick = async () => {
    if (orderFile?.url) {
      openPdf(orderFile.url, `PurchaseOrder_${orderId}`);
      return;
    }
    try {
      const { data } = await client.query<
        GeneratePurchaseOrderPdfQuery,
        GeneratePurchaseOrderPdfQueryVariables
      >({ query: GeneratePurchaseOrderPdfDocument, variables: { orderId } });
      const url = getDataOrNull(data.orderGeneratePdf)?.url;
      if (!url) throw new Error("Failed to generate PDF");
      openPdf(url, `PurchaseOrder_${orderId}`);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  return (
    <Tooltip title={t("Download PDF")}>
      <IconButton size={size} color="primary" onClick={onClick}>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
};
