import { useApolloClient } from "@apollo/client";
import { StatusChip } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import {
  PageSubHeader,
  PageSubHeaderInjectedProps,
} from "../../../commons/layout/PageSubHeader";
import { useHeaderBox_ModifyItemInfoMutation } from "../../../features/doc-items/boxes/HeaderBox.generated";
import {
  TemplateListOverviewSubHeader__TemplateListFragment,
  useModifyTemplateList_OverviewSubHeaderMutation,
} from "./TemplateListOverViewSubHeader.generated";

interface Props {
  templateList: TemplateListOverviewSubHeader__TemplateListFragment;
  pathToDocList: string;
}

export const TemplateListOverviewSubHeader = ({
  templateList,
  isHeaderVisible,
  setHeaderVisible,
  pathToDocList,
}: Props & PageSubHeaderInjectedProps) => {
  const { t } = useTranslate(["TemplateLists", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyTemplateList] = useModifyTemplateList_OverviewSubHeaderMutation({
    client,
  });
  const handleTitleChange = async (title: string) => {
    await modifyTemplateList({
      variables: {
        input: {
          templateListId: templateList.id,
          values: { title },
        },
      },
    });
  };

  return (
    <PageSubHeader
      title={templateList.title}
      hideTitleOnMobile={false}
      isHeaderVisible={isHeaderVisible}
      setHeaderVisible={setHeaderVisible}
      // status={
      //   templateList.isDefault ? (
      //     <StatusChip
      //       data-test-id="templateList-default-chip"
      //       label={t("Default", { ns: "TemplateLists" })}
      //       color={"success"}
      //       variant={"outlined"}
      //     />
      //   ) : null
      // }
      handleTitleChange={async newValue => {
        await handleTitleChange(newValue);
        enqueueSnackbar(t("Title updated", { ns: "Global" }));
      }}
      titleEditTooltip={t("Rename", { ns: "Global" })}
    />
  );
};
