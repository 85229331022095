/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { TemplateListOverviewSubHeader__TemplateListFragmentDoc } from './TemplateListOverViewSubHeader.generated';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../../../features/doc-items/Fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateListOverviewQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TemplateListOverviewQuery = { templateList: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'TemplateList', id: string, title: string, isDefault: boolean, templates: Array<{ __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string, customTags: Array<string>, createdAt: any, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, implementsTemplateType?: { __typename: 'TemplateTypeAtRevision', atRevision: number, templateType: { __typename: 'TemplateType', id: string, title: string } } | null }> } };

export type TemplateListOverview_TemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string, customTags: Array<string>, createdAt: any, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, implementsTemplateType?: { __typename: 'TemplateTypeAtRevision', atRevision: number, templateType: { __typename: 'TemplateType', id: string, title: string } } | null };

export type AddTemplatesToTemplateListMutationVariables = Types.Exact<{
  input: Types.AddTemplatesToTemplateListInput;
}>;


export type AddTemplatesToTemplateListMutation = { addTemplatesToTemplateList: { __typename: 'AddTemplatesToTemplateListResult', templateList: { __typename: 'TemplateList', id: string, templates: Array<{ __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null }> } } };

export type RemoveTemplatesFromTemplateListMutationVariables = Types.Exact<{
  input: Types.RemoveTemplatesFromTemplateListInput;
}>;


export type RemoveTemplatesFromTemplateListMutation = { removeTemplatesFromTemplateList: { __typename: 'RemoveTemplatesFromTemplateListResult', templateList: { __typename: 'TemplateList', id: string, templates: Array<{ __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null }> } } };

export type SetTemplatesOrderInTemplateListMutationVariables = Types.Exact<{
  input: Types.SetTemplatesOrderInTemplateListInput;
}>;


export type SetTemplatesOrderInTemplateListMutation = { setTemplatesOrderInTemplateList: { __typename: 'SetTemplatesOrderInTemplateListResult', templateList: { __typename: 'TemplateList', id: string, templates: Array<{ __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null }> } } };

export const TemplateListOverview_TemplateFragmentDoc = gql`
    fragment TemplateListOverview_Template on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  title
  customTags
  createdAt
  proposedCalculation {
    ...ItemCalculation
  }
  implementsTemplateType {
    atRevision
    templateType {
      id
      title
    }
  }
}
    ${ItemCalculationFragmentDoc}`;
export const TemplateListOverviewDocument = gql`
    query TemplateListOverview($id: ID!) {
  templateList(id: $id) {
    ... on TemplateList {
      id
      title
      ...TemplateListOverviewSubHeader__TemplateList
      templates {
        id
        resolvedAsReadModelVersionNumber
        ...TemplateListOverview_Template
      }
    }
  }
}
    ${TemplateListOverviewSubHeader__TemplateListFragmentDoc}
${TemplateListOverview_TemplateFragmentDoc}`;

/**
 * __useTemplateListOverviewQuery__
 *
 * To run a query within a React component, call `useTemplateListOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateListOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateListOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateListOverviewQuery(baseOptions: Apollo.QueryHookOptions<TemplateListOverviewQuery, TemplateListOverviewQueryVariables> & ({ variables: TemplateListOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateListOverviewQuery, TemplateListOverviewQueryVariables>(TemplateListOverviewDocument, options);
      }
export function useTemplateListOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateListOverviewQuery, TemplateListOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateListOverviewQuery, TemplateListOverviewQueryVariables>(TemplateListOverviewDocument, options);
        }
export function useTemplateListOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateListOverviewQuery, TemplateListOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateListOverviewQuery, TemplateListOverviewQueryVariables>(TemplateListOverviewDocument, options);
        }
export type TemplateListOverviewQueryHookResult = ReturnType<typeof useTemplateListOverviewQuery>;
export type TemplateListOverviewLazyQueryHookResult = ReturnType<typeof useTemplateListOverviewLazyQuery>;
export type TemplateListOverviewSuspenseQueryHookResult = ReturnType<typeof useTemplateListOverviewSuspenseQuery>;
export type TemplateListOverviewQueryResult = Apollo.QueryResult<TemplateListOverviewQuery, TemplateListOverviewQueryVariables>;
export const AddTemplatesToTemplateListDocument = gql`
    mutation AddTemplatesToTemplateList($input: AddTemplatesToTemplateListInput!) {
  addTemplatesToTemplateList(input: $input) {
    templateList {
      id
      templates {
        id
        resolvedAsReadModelVersionNumber
      }
    }
  }
}
    `;
export type AddTemplatesToTemplateListMutationFn = Apollo.MutationFunction<AddTemplatesToTemplateListMutation, AddTemplatesToTemplateListMutationVariables>;

/**
 * __useAddTemplatesToTemplateListMutation__
 *
 * To run a mutation, you first call `useAddTemplatesToTemplateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplatesToTemplateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplatesToTemplateListMutation, { data, loading, error }] = useAddTemplatesToTemplateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTemplatesToTemplateListMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplatesToTemplateListMutation, AddTemplatesToTemplateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplatesToTemplateListMutation, AddTemplatesToTemplateListMutationVariables>(AddTemplatesToTemplateListDocument, options);
      }
export type AddTemplatesToTemplateListMutationHookResult = ReturnType<typeof useAddTemplatesToTemplateListMutation>;
export type AddTemplatesToTemplateListMutationResult = Apollo.MutationResult<AddTemplatesToTemplateListMutation>;
export type AddTemplatesToTemplateListMutationOptions = Apollo.BaseMutationOptions<AddTemplatesToTemplateListMutation, AddTemplatesToTemplateListMutationVariables>;
export const RemoveTemplatesFromTemplateListDocument = gql`
    mutation RemoveTemplatesFromTemplateList($input: RemoveTemplatesFromTemplateListInput!) {
  removeTemplatesFromTemplateList(input: $input) {
    templateList {
      id
      templates {
        id
        resolvedAsReadModelVersionNumber
      }
    }
  }
}
    `;
export type RemoveTemplatesFromTemplateListMutationFn = Apollo.MutationFunction<RemoveTemplatesFromTemplateListMutation, RemoveTemplatesFromTemplateListMutationVariables>;

/**
 * __useRemoveTemplatesFromTemplateListMutation__
 *
 * To run a mutation, you first call `useRemoveTemplatesFromTemplateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTemplatesFromTemplateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTemplatesFromTemplateListMutation, { data, loading, error }] = useRemoveTemplatesFromTemplateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTemplatesFromTemplateListMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTemplatesFromTemplateListMutation, RemoveTemplatesFromTemplateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTemplatesFromTemplateListMutation, RemoveTemplatesFromTemplateListMutationVariables>(RemoveTemplatesFromTemplateListDocument, options);
      }
export type RemoveTemplatesFromTemplateListMutationHookResult = ReturnType<typeof useRemoveTemplatesFromTemplateListMutation>;
export type RemoveTemplatesFromTemplateListMutationResult = Apollo.MutationResult<RemoveTemplatesFromTemplateListMutation>;
export type RemoveTemplatesFromTemplateListMutationOptions = Apollo.BaseMutationOptions<RemoveTemplatesFromTemplateListMutation, RemoveTemplatesFromTemplateListMutationVariables>;
export const SetTemplatesOrderInTemplateListDocument = gql`
    mutation SetTemplatesOrderInTemplateList($input: SetTemplatesOrderInTemplateListInput!) {
  setTemplatesOrderInTemplateList(input: $input) {
    templateList {
      id
      templates {
        id
        resolvedAsReadModelVersionNumber
      }
    }
  }
}
    `;
export type SetTemplatesOrderInTemplateListMutationFn = Apollo.MutationFunction<SetTemplatesOrderInTemplateListMutation, SetTemplatesOrderInTemplateListMutationVariables>;

/**
 * __useSetTemplatesOrderInTemplateListMutation__
 *
 * To run a mutation, you first call `useSetTemplatesOrderInTemplateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTemplatesOrderInTemplateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTemplatesOrderInTemplateListMutation, { data, loading, error }] = useSetTemplatesOrderInTemplateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTemplatesOrderInTemplateListMutation(baseOptions?: Apollo.MutationHookOptions<SetTemplatesOrderInTemplateListMutation, SetTemplatesOrderInTemplateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTemplatesOrderInTemplateListMutation, SetTemplatesOrderInTemplateListMutationVariables>(SetTemplatesOrderInTemplateListDocument, options);
      }
export type SetTemplatesOrderInTemplateListMutationHookResult = ReturnType<typeof useSetTemplatesOrderInTemplateListMutation>;
export type SetTemplatesOrderInTemplateListMutationResult = Apollo.MutationResult<SetTemplatesOrderInTemplateListMutation>;
export type SetTemplatesOrderInTemplateListMutationOptions = Apollo.BaseMutationOptions<SetTemplatesOrderInTemplateListMutation, SetTemplatesOrderInTemplateListMutationVariables>;