import React from "react";
import {
  ClipboardRecordType,
  useClipboard,
} from "../../commons/hooks/useClipboard";
import { Props2 } from "../../../clients/graphqlTypes";

const KEY = "item-properties";

export const usePropertiesClipboard = () => {
  const [clipboard, setClipboard] = useClipboard();

  const [itemTitle, properties, group] = React.useMemo(() => {
    const [itemTitle, properties] = (clipboard[KEY] ?? []) as [
      string,
      Props2[] | null,
    ];
    const firstGroup = properties?.[0]?.group;
    const group =
      firstGroup && properties?.every(p => p.group === firstGroup)
        ? firstGroup
        : null;
    return [itemTitle, properties, group];
  }, [clipboard]);

  const setContent = React.useCallback(
    (itemId: string, newContent: Props2[] | null) => {
      setClipboard((v: ClipboardRecordType | undefined) => ({
        ...(v ?? {}),
        [KEY]: [itemId, newContent],
      }));
    },
    [setClipboard]
  );

  return { itemTitle, properties, group, setContent };
};
