/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseExpressionValidation_CompileDocIsolatedExpressionQueryVariables = Types.Exact<{
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
  overrides?: Types.InputMaybe<Array<Types.CompileDocOverrideInput>>;
}>;


export type UseExpressionValidation_CompileDocIsolatedExpressionQuery = { compileDocIsolatedExpression: { __typename: 'CompileDocIsolatedExpressionResult', results: Array<{ __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute', attribute: string, messageText: string } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp', key: string, messageText: string } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression', messageText: string } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping', toKey: string, messageText: string } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf', messageText: string, itemRecommendationId: string } | { __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf', messageText: string, itemRecommendationId: string }> } };


export const UseExpressionValidation_CompileDocIsolatedExpressionDocument = gql`
    query UseExpressionValidation_CompileDocIsolatedExpression($docId: ID!, $itemId: ID!, $overrides: [CompileDocOverrideInput!]) {
  compileDocIsolatedExpression(
    input: {docId: $docId, deep: false, filterItemId: $itemId, overrides: $overrides}
  ) {
    results {
      ... on CompileDocIsolatedExpressionResultDiagnosticAttribute {
        __typename
        attribute
        messageText
      }
      ... on CompileDocIsolatedExpressionResultDiagnosticComputedProp {
        __typename
        key
        messageText
      }
      ... on CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression {
        __typename
        messageText
      }
      ... on CompileDocIsolatedExpressionResultDiagnosticPropertyMapping {
        __typename
        toKey
        messageText
      }
      ... on CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf {
        __typename
        messageText
        itemRecommendationId
      }
      ... on CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf {
        __typename
        messageText
        itemRecommendationId
      }
    }
  }
}
    `;

/**
 * __useUseExpressionValidation_CompileDocIsolatedExpressionQuery__
 *
 * To run a query within a React component, call `useUseExpressionValidation_CompileDocIsolatedExpressionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseExpressionValidation_CompileDocIsolatedExpressionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseExpressionValidation_CompileDocIsolatedExpressionQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *      overrides: // value for 'overrides'
 *   },
 * });
 */
export function useUseExpressionValidation_CompileDocIsolatedExpressionQuery(baseOptions: Apollo.QueryHookOptions<UseExpressionValidation_CompileDocIsolatedExpressionQuery, UseExpressionValidation_CompileDocIsolatedExpressionQueryVariables> & ({ variables: UseExpressionValidation_CompileDocIsolatedExpressionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseExpressionValidation_CompileDocIsolatedExpressionQuery, UseExpressionValidation_CompileDocIsolatedExpressionQueryVariables>(UseExpressionValidation_CompileDocIsolatedExpressionDocument, options);
      }
export function useUseExpressionValidation_CompileDocIsolatedExpressionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseExpressionValidation_CompileDocIsolatedExpressionQuery, UseExpressionValidation_CompileDocIsolatedExpressionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseExpressionValidation_CompileDocIsolatedExpressionQuery, UseExpressionValidation_CompileDocIsolatedExpressionQueryVariables>(UseExpressionValidation_CompileDocIsolatedExpressionDocument, options);
        }
export function useUseExpressionValidation_CompileDocIsolatedExpressionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseExpressionValidation_CompileDocIsolatedExpressionQuery, UseExpressionValidation_CompileDocIsolatedExpressionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseExpressionValidation_CompileDocIsolatedExpressionQuery, UseExpressionValidation_CompileDocIsolatedExpressionQueryVariables>(UseExpressionValidation_CompileDocIsolatedExpressionDocument, options);
        }
export type UseExpressionValidation_CompileDocIsolatedExpressionQueryHookResult = ReturnType<typeof useUseExpressionValidation_CompileDocIsolatedExpressionQuery>;
export type UseExpressionValidation_CompileDocIsolatedExpressionLazyQueryHookResult = ReturnType<typeof useUseExpressionValidation_CompileDocIsolatedExpressionLazyQuery>;
export type UseExpressionValidation_CompileDocIsolatedExpressionSuspenseQueryHookResult = ReturnType<typeof useUseExpressionValidation_CompileDocIsolatedExpressionSuspenseQuery>;
export type UseExpressionValidation_CompileDocIsolatedExpressionQueryResult = Apollo.QueryResult<UseExpressionValidation_CompileDocIsolatedExpressionQuery, UseExpressionValidation_CompileDocIsolatedExpressionQueryVariables>;