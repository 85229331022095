/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateLists_TemplateListFragment = { __typename: 'TemplateList', id: string, title: string, createdAt: any, isDefault: boolean };

export type TemplateListsQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sorting?: Types.InputMaybe<Array<Types.TemplateListsSorting>>;
}>;


export type TemplateListsQuery = { templateLists: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'TemplateListConnection', totalCount: number, edges: Array<{ __typename: 'TemplateListEdge', node: { __typename: 'TemplateList', id: string, title: string, createdAt: any, isDefault: boolean } }> } };

export type DeleteTemplateListMutationVariables = Types.Exact<{
  input: Types.DeleteTemplateListInput;
}>;


export type DeleteTemplateListMutation = { deleteTemplateList: { __typename: 'DeleteTemplateListResult', ok: boolean } };

export const TemplateLists_TemplateListFragmentDoc = gql`
    fragment TemplateLists_TemplateList on TemplateList {
  id
  title
  createdAt
  isDefault
}
    `;
export const TemplateListsDocument = gql`
    query TemplateLists($offset: Int, $limit: Int!, $searchTerm: String, $sorting: [TemplateListsSorting!]) {
  templateLists(
    offset: $offset
    limit: $limit
    search: $searchTerm
    sorting: $sorting
  ) {
    ... on TemplateListConnection {
      edges {
        node {
          ...TemplateLists_TemplateList
        }
      }
      totalCount
    }
  }
}
    ${TemplateLists_TemplateListFragmentDoc}`;

/**
 * __useTemplateListsQuery__
 *
 * To run a query within a React component, call `useTemplateListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateListsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useTemplateListsQuery(baseOptions: Apollo.QueryHookOptions<TemplateListsQuery, TemplateListsQueryVariables> & ({ variables: TemplateListsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateListsQuery, TemplateListsQueryVariables>(TemplateListsDocument, options);
      }
export function useTemplateListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateListsQuery, TemplateListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateListsQuery, TemplateListsQueryVariables>(TemplateListsDocument, options);
        }
export function useTemplateListsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateListsQuery, TemplateListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateListsQuery, TemplateListsQueryVariables>(TemplateListsDocument, options);
        }
export type TemplateListsQueryHookResult = ReturnType<typeof useTemplateListsQuery>;
export type TemplateListsLazyQueryHookResult = ReturnType<typeof useTemplateListsLazyQuery>;
export type TemplateListsSuspenseQueryHookResult = ReturnType<typeof useTemplateListsSuspenseQuery>;
export type TemplateListsQueryResult = Apollo.QueryResult<TemplateListsQuery, TemplateListsQueryVariables>;
export const DeleteTemplateListDocument = gql`
    mutation DeleteTemplateList($input: DeleteTemplateListInput!) {
  deleteTemplateList(input: $input) {
    ok
  }
}
    `;
export type DeleteTemplateListMutationFn = Apollo.MutationFunction<DeleteTemplateListMutation, DeleteTemplateListMutationVariables>;

/**
 * __useDeleteTemplateListMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateListMutation, { data, loading, error }] = useDeleteTemplateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTemplateListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateListMutation, DeleteTemplateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateListMutation, DeleteTemplateListMutationVariables>(DeleteTemplateListDocument, options);
      }
export type DeleteTemplateListMutationHookResult = ReturnType<typeof useDeleteTemplateListMutation>;
export type DeleteTemplateListMutationResult = Apollo.MutationResult<DeleteTemplateListMutation>;
export type DeleteTemplateListMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateListMutation, DeleteTemplateListMutationVariables>;