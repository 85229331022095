import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import StoreIcon from "@mui/icons-material/Store";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { RestrictedByOrganisationPermission } from "../../auth/RestrictedByOrganisationPermission";
import { PageTopbarItem } from "../../commons/layout/Page";
import { PageNotFound } from "../../commons/PageNotFound";
import { TemplateListIcon } from "../../features/templateLists/TemplateListIcon";
import { TemplateIcon } from "../../features/templates/TemplateIcon";
import { TemplateTypeIcon } from "../../features/templateTypes/TemplateTypeIcon";
import { TemplateListOverview } from "./quote/TemplateListOverview";
import { TemplateLists } from "./quote/TemplateLists";
import { TemplateQuoteRoutes } from "./quote/TemplateQuoteRoutes";
import { TemplateQuotes } from "./quote/TemplateQuotes";
import { TemplateQuoteVersionRoutes } from "./quote/TemplateQuoteVersionRoutes";
import { TemplateTypeOverview } from "./quote/TemplateTypeOverview";
import { TemplateTypes } from "./quote/TemplateTypes";

type PageSubmenuItem = PageTopbarItem;

const submenuItemsEmpty: PageSubmenuItem[] = [];

export const TemplatesRoutes: React.FC = () => {
  const { t } = useTranslate("Templates");

  const submenuItems = React.useMemo(() => {
    const items: PageSubmenuItem[] = [
      {
        name: "templates-quotes-all",
        label: t("All Available"),
        path: `/templates/documents/all`,
        icon: <TemplateIcon />,
      },
      {
        name: "templates-quotes-my",
        label: t("My Templates"),
        path: `/templates/documents/my`,
        icon: <CorporateFareIcon />,
        permission: "MANAGE_TEMPLATE",
      },
      {
        name: "templates-quotes-store",
        label: t("Meister Store"),
        path: `/templates/documents/store`,
        icon: <StoreIcon />,
        permission: "SHARE_TEMPLATE",
      },
      {
        name: "templates-types",
        label: t("Template types"),
        path: `/templates/types`,
        icon: <TemplateTypeIcon />,
      },
      {
        name: "templates-lists",
        label: t("Template lists"),
        path: `/templates/lists`,
        icon: <TemplateListIcon />,
        permission: "MANAGE_TEMPLATE",
      },
    ];

    return items.filter(item => !item.isHidden);
  }, [t]);

  const pathToDocList = "/templates/documents";
  const pathToTemplateTypesList = "/templates/types";
  const pathToTemplateListsList = "/templates/lists";

  return (
    <Routes>
      <Route index element={<Navigate to="documents" replace />} />

      <Route path="documents">
        <Route index element={<Navigate to={"all"} replace />} />
        <Route
          path={"all"}
          element={
            <TemplateQuotes
              key={"all"}
              submenuItems={submenuItems}
              section="ALL"
              pathToDocList={pathToDocList}
            />
          }
        />
        <Route
          path={"my"}
          element={
            <TemplateQuotes
              key={"my"}
              submenuItems={submenuItems}
              section="MY"
              pathToDocList={pathToDocList}
            />
          }
        />
        <Route
          path={"store"}
          element={
            <RestrictedByOrganisationPermission permission="SHARE_TEMPLATE">
              <TemplateQuotes
                key={"store"}
                submenuItems={submenuItems}
                section="MEISTER_STORE"
                pathToDocList={pathToDocList}
              />
            </RestrictedByOrganisationPermission>
          }
        />
        <Route
          path={":id/latest/*"}
          element={
            <RestrictedByOrganisationPermission permission="MANAGE_TEMPLATE">
              <TemplateQuoteRoutes submenuItems={submenuItemsEmpty} />
            </RestrictedByOrganisationPermission>
          }
        />
        <Route
          path={":id/:versionNumber/*"}
          element={
            <TemplateQuoteVersionRoutes submenuItems={submenuItemsEmpty} />
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path="types">
        <Route index element={<Navigate to={"all"} replace />} />
        <Route
          path={"all"}
          element={
            <TemplateTypes
              key={"all"}
              submenuItems={submenuItems}
              pathToDocList={pathToTemplateTypesList}
            />
          }
        />
        <Route
          path={":id"}
          element={
            <TemplateTypeOverview
              pathToDocList={pathToTemplateTypesList}
              submenuItems={submenuItemsEmpty}
            />
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path="lists">
        <Route index element={<Navigate to={"all"} replace />} />
        <Route
          path={"all"}
          element={
            <TemplateLists
              key={"all"}
              submenuItems={submenuItems}
              pathToDocList={pathToTemplateListsList}
            />
          }
        />
        <Route
          path={":id"}
          element={
            <TemplateListOverview
              pathToDocList={pathToTemplateListsList}
              pathToTemplatesDocList={pathToDocList}
              submenuItems={submenuItemsEmpty}
            />
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* Legacy redirects */}
      <Route path="quotes/*" element={<LegacyRouteRedirect />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

function LegacyRouteRedirect() {
  const location = useLocation();
  const newPath = location.pathname.replace(
    "/templates/quotes",
    "/templates/documents"
  );

  return <Navigate to={newPath} replace />;
}
