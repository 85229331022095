/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { PimProductTypePropertyTypeFragmentDoc } from '../Product.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTypesSearchModalQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String']['input'];
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  sorting?: Types.InputMaybe<Array<Types.PimSearchProductTypesSortingInput>>;
}>;


export type ProductTypesSearchModalQuery = { pimSearchProductTypes: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchProductTypesResult', totalCount: number, productTypes: Array<{ __typename: 'PimProductType', id: string, key: string, label: string, description?: string | null, labelSynonyms: Array<string>, typeSystem: Types.PimProductTypeSystemSystemType, propertyTypes: Array<{ __typename: 'PimProductTypePropertyType', id: string, propertyType: { __typename: 'PimPropertyType', id: string, key: string, label: string, kind: Types.PimPropertyTypeKind, typeSystem: Types.PimProductTypeSystemSystemType, unit?: string | null }, propertyTypeValues: Array<{ __typename: 'PimPropertyTypeValue', id: string, key: string, label: string }> }> }> } };

export type ProductTypesSearchModal_PimProductTypeFragment = { __typename: 'PimProductType', id: string, key: string, label: string, description?: string | null, labelSynonyms: Array<string>, typeSystem: Types.PimProductTypeSystemSystemType, propertyTypes: Array<{ __typename: 'PimProductTypePropertyType', id: string, propertyType: { __typename: 'PimPropertyType', id: string, key: string, label: string, kind: Types.PimPropertyTypeKind, typeSystem: Types.PimProductTypeSystemSystemType, unit?: string | null }, propertyTypeValues: Array<{ __typename: 'PimPropertyTypeValue', id: string, key: string, label: string }> }> };

export const ProductTypesSearchModal_PimProductTypeFragmentDoc = gql`
    fragment ProductTypesSearchModal_PimProductType on PimProductType {
  id
  key
  label
  description
  propertyTypes {
    ...PimProductTypePropertyType
  }
  labelSynonyms
  typeSystem
}
    ${PimProductTypePropertyTypeFragmentDoc}`;
export const ProductTypesSearchModalDocument = gql`
    query ProductTypesSearchModal($searchTerm: String!, $limit: Int!, $offset: Int!, $sorting: [PimSearchProductTypesSortingInput!]) {
  pimSearchProductTypes(
    searchTerm: $searchTerm
    limit: $limit
    offset: $offset
    sorting: $sorting
  ) {
    ... on PimSearchProductTypesResult {
      productTypes {
        ...ProductTypesSearchModal_PimProductType
      }
      totalCount
    }
  }
}
    ${ProductTypesSearchModal_PimProductTypeFragmentDoc}`;

/**
 * __useProductTypesSearchModalQuery__
 *
 * To run a query within a React component, call `useProductTypesSearchModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypesSearchModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypesSearchModalQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useProductTypesSearchModalQuery(baseOptions: Apollo.QueryHookOptions<ProductTypesSearchModalQuery, ProductTypesSearchModalQueryVariables> & ({ variables: ProductTypesSearchModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTypesSearchModalQuery, ProductTypesSearchModalQueryVariables>(ProductTypesSearchModalDocument, options);
      }
export function useProductTypesSearchModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTypesSearchModalQuery, ProductTypesSearchModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTypesSearchModalQuery, ProductTypesSearchModalQueryVariables>(ProductTypesSearchModalDocument, options);
        }
export function useProductTypesSearchModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductTypesSearchModalQuery, ProductTypesSearchModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductTypesSearchModalQuery, ProductTypesSearchModalQueryVariables>(ProductTypesSearchModalDocument, options);
        }
export type ProductTypesSearchModalQueryHookResult = ReturnType<typeof useProductTypesSearchModalQuery>;
export type ProductTypesSearchModalLazyQueryHookResult = ReturnType<typeof useProductTypesSearchModalLazyQuery>;
export type ProductTypesSearchModalSuspenseQueryHookResult = ReturnType<typeof useProductTypesSearchModalSuspenseQuery>;
export type ProductTypesSearchModalQueryResult = Apollo.QueryResult<ProductTypesSearchModalQuery, ProductTypesSearchModalQueryVariables>;