import { useApolloClient } from "@apollo/client";
import { Button, Checkbox } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { assertNever } from "../../utils";
import {
  useSetTaskItemDoneMutation,
  useSetTaskItemNotDoneMutation,
} from "./Tasks.generated";

interface Props {
  projectId: string;
  docId: string;
  itemId: string;
  itemIsDone: boolean;
  buttonType: "button" | "icon";
  disabled: boolean;
  onBeforeTaskDone: (
    itemId: string,
    docId: string,
    projectId: string,
    newIsDone: boolean
  ) => boolean | Promise<boolean>;
  onAfterTaskDone: (
    itemId: string,
    docId: string,
    projectId: string,
    newIsDone: boolean
  ) => void | Promise<void>;
}

export const ToggleTaskCompletionIconButton = React.memo(
  ({
    projectId,
    docId,
    itemId,
    itemIsDone,
    buttonType,
    disabled,
    onBeforeTaskDone,
    onAfterTaskDone,
  }: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState<boolean>(false);

    const client = useApolloClient();
    const [setTaskItemDoneMutation] = useSetTaskItemDoneMutation({ client });
    const [setTaskItemNotDoneMutation] = useSetTaskItemNotDoneMutation({
      client,
    });

    const onClick = async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const newIsDone = !itemIsDone;
      setLoading(true);

      try {
        const isAllowed = await onBeforeTaskDone(
          itemId,
          docId,
          projectId,
          newIsDone
        );
        if (isAllowed === false) return;
        try {
          if (newIsDone) {
            await setTaskItemDoneMutation({
              variables: { input: { docId, projectId, itemId } },
            });
          } else {
            await setTaskItemNotDoneMutation({
              variables: { input: { docId, projectId, itemId } },
            });
          }
        } catch (e) {
          if (e instanceof Error)
            enqueueSnackbar(e.message, { variant: "error" });
        }
        await onAfterTaskDone(itemId, docId, projectId, newIsDone);
      } finally {
        setLoading(false);
      }
    };

    return (
      <CompleteTaskButton
        type={buttonType}
        loading={loading}
        disabled={disabled}
        isDone={itemIsDone}
        onClick={onClick}
      />
    );
  }
);

const CompleteTaskButton: React.FC<{
  type: "button" | "icon";
  loading: boolean;
  disabled: boolean;
  isDone: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({ type, loading, disabled, isDone, onClick }) => {
  const { t } = useTranslate("TaskPriority");

  switch (type) {
    case "button": {
      return (
        <Button
          type="button"
          color="secondary"
          variant="outlined"
          size="medium"
          disabled={disabled || loading}
          onClick={onClick}
        >
          {isDone ? t("Uncheck") : t("Check")}
        </Button>
      );
    }
    case "icon": {
      return (
        <Checkbox
          checked={isDone}
          disabled={disabled || loading}
          onClick={onClick}
        />
      );
    }
    default:
      throw assertNever(type);
  }
};
