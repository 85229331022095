/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { PimProductTypePropertyTypeFragmentDoc } from '../Product.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTypeOverviewModalQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProductTypeOverviewModalQuery = { pimProductType: { __typename: 'MissingCapabilities' } | { __typename: 'PimProductType', id: string, key: string, label: string, description?: string | null, labelSynonyms: Array<string>, typeSystem: Types.PimProductTypeSystemSystemType, propertyTypes: Array<{ __typename: 'PimProductTypePropertyType', id: string, propertyType: { __typename: 'PimPropertyType', id: string, key: string, label: string, kind: Types.PimPropertyTypeKind, typeSystem: Types.PimProductTypeSystemSystemType, unit?: string | null }, propertyTypeValues: Array<{ __typename: 'PimPropertyTypeValue', id: string, key: string, label: string }> }> } };


export const ProductTypeOverviewModalDocument = gql`
    query ProductTypeOverviewModal($id: ID!) {
  pimProductType(id: $id) {
    ... on PimProductType {
      id
      key
      label
      description
      propertyTypes {
        ...PimProductTypePropertyType
      }
      labelSynonyms
      typeSystem
    }
  }
}
    ${PimProductTypePropertyTypeFragmentDoc}`;

/**
 * __useProductTypeOverviewModalQuery__
 *
 * To run a query within a React component, call `useProductTypeOverviewModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypeOverviewModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypeOverviewModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductTypeOverviewModalQuery(baseOptions: Apollo.QueryHookOptions<ProductTypeOverviewModalQuery, ProductTypeOverviewModalQueryVariables> & ({ variables: ProductTypeOverviewModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTypeOverviewModalQuery, ProductTypeOverviewModalQueryVariables>(ProductTypeOverviewModalDocument, options);
      }
export function useProductTypeOverviewModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTypeOverviewModalQuery, ProductTypeOverviewModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTypeOverviewModalQuery, ProductTypeOverviewModalQueryVariables>(ProductTypeOverviewModalDocument, options);
        }
export function useProductTypeOverviewModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductTypeOverviewModalQuery, ProductTypeOverviewModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductTypeOverviewModalQuery, ProductTypeOverviewModalQueryVariables>(ProductTypeOverviewModalDocument, options);
        }
export type ProductTypeOverviewModalQueryHookResult = ReturnType<typeof useProductTypeOverviewModalQuery>;
export type ProductTypeOverviewModalLazyQueryHookResult = ReturnType<typeof useProductTypeOverviewModalLazyQuery>;
export type ProductTypeOverviewModalSuspenseQueryHookResult = ReturnType<typeof useProductTypeOverviewModalSuspenseQuery>;
export type ProductTypeOverviewModalQueryResult = Apollo.QueryResult<ProductTypeOverviewModalQuery, ProductTypeOverviewModalQueryVariables>;