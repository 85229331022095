import { useApolloClient } from "@apollo/client";
import { assert, assertNever, notNull } from "@msys/common";
import {
  CardContainer,
  MenuButton,
  MenuItemWithIcon,
  ModalOpenButton,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingsIcon from "@mui/icons-material/Settings";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  IconButton,
  List,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import {
  ItemType,
  ModifyItemRecommendedTemplateTypeValuesInput,
  ModifyItemRecommendedTemplateValuesInput,
} from "../../../../clients/graphqlTypes";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal";
import { EditRecommendedTemplateModal } from "../../templates/EditRecommendedTemplateModal";
import { EditRecommendedTemplateTypeModal } from "../../templates/EditRecommendedTemplateTypeModal";
import { TemplateIcon } from "../../templates/TemplateIcon";
import { TemplateTypeIcon } from "../../templateTypes/TemplateTypeIcon";
import { TemplateTypesSearchModal } from "../../templateTypes/TemplateTypesSearchModal";
import { getDefaultCreatableSubItemTypes } from "../constraints";
import { SearchItemInputModal } from "../SearchItemInputModal";
import { RecommendationListItem } from "./RecommendationListItem";
import {
  RecommendationsConfigBox_ItemFragment,
  useAddRecommendedTemplateMutation,
  useAddRecommendedTemplateTypeMutation,
  useModifyRecommendedTemplateMutation,
  useModifyRecommendedTemplateTypeMutation,
  useRemoveRecommendedTemplateMutation,
  useRemoveRecommendedTemplateTypeMutation,
  useSetRecommendedTemplateRankMutation,
  useSetRecommendedTemplateTypeRankMutation,
} from "./RecommendationsConfigBox.generated";

interface Props {
  item: RecommendationsConfigBox_ItemFragment;
  canEdit: boolean;
  documentItemTypes: ItemType[];
  refetchQueries?: string[];
}

export const RecommendationsConfigBox = ({
  item,
  canEdit,
  documentItemTypes,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["QuoteItem"]);
  const { enqueueSnackbar } = useSnackbar();

  const allowedItemTypes = getDefaultCreatableSubItemTypes(
    item.type,
    documentItemTypes
  );

  const { recommendationsConfig = [], recommendations = [] } = item;

  const {
    addRecommendedTemplate,
    modifyRecommendedTemplate,
    removeRecommendedTemplate,
    setRecommendedTemplateRank,
    addLoading,
    modifyLoading,
    removeLoading,
    setRankLoading,
  } = useRecommendedTemplateMutations(item.docId, item.id);
  const {
    addRecommendedTemplateType,
    modifyRecommendedTemplateType,
    removeRecommendedTemplateType,
    setRecommendedTemplateTypeRank,
    addTypeLoading,
    modifyTypeLoading,
    removeTypeLoading,
    setRankTypeLoading,
  } = useRecommendedTemplateTypeMutations(item.docId, item.id);

  if (allowedItemTypes.length === 0) return null;

  const alreadyAddedTemplateIds = recommendations
    .map(config =>
      config.recommendedEntity.__typename === "RecommendedTemplate"
        ? config.recommendedEntity.templateId
        : null
    )
    .filter(notNull);
  return (
    <CardContainer
      title={t("Recommended templates", { ns: "QuoteItem" })}
      isExpandable
      ActionButton={
        canEdit && (
          <MenuButton Icon={<AddIcon />}>
            <ModalOpenButton
              Modal={SearchItemInputModal}
              modalProps={{
                initialSearchTab: "templates",
                initialSearchValue: "",
                allowedSearchTabs: ["templates"],
                excludeTemplateIds: [item.docId, ...alreadyAddedTemplateIds],
                rootItemTypes: allowedItemTypes,
                handleSelect: async ({ templates }) => {
                  await Promise.all(
                    templates.map(async template => {
                      await addRecommendedTemplate(
                        template.template.id,
                        template.template.resolvedAsReadModelVersionNumber! /// FIXME
                      );
                    })
                  );
                },
                showQuantityInput: false,
                AddToListIcon: AddIcon,
              }}
            >
              <MenuItemWithIcon icon={<TemplateIcon />} disabled={addLoading}>
                {t("Add template", { ns: "QuoteItem" })}
              </MenuItemWithIcon>
            </ModalOpenButton>
            <ModalOpenButton
              Modal={TemplateTypesSearchModal}
              modalProps={{
                handleTemplateTypeChoice: async templateType => {
                  await addRecommendedTemplateType(templateType.id);
                },
                excludeTemplateTypeIds: recommendationsConfig.reduce<string[]>(
                  (acc, config) =>
                    config.recommendedEntityConfig.__typename ===
                    "RecommendedTemplateTypeConfig"
                      ? [...acc, config.recommendedEntityConfig.templateTypeId]
                      : acc,
                  []
                ),
              }}
            >
              <MenuItemWithIcon
                icon={<TemplateTypeIcon />}
                disabled={addTypeLoading}
              >
                {t("Add template type", { ns: "QuoteItem" })}
              </MenuItemWithIcon>
            </ModalOpenButton>
          </MenuButton>
        )
      }
    >
      {recommendations.length > 0 ? (
        <List dense disablePadding>
          {recommendations.map(recommendation => {
            const rank = recommendation.rank;
            if (
              recommendation.recommendedEntity.__typename ===
              "RecommendedTemplate"
            ) {
              const templateId = recommendation.recommendedEntity.templateId;
              const recommendationConfig = recommendationsConfig.find(
                config =>
                  config.recommendedEntityConfig.__typename ===
                    "RecommendedTemplateConfig" &&
                  config.recommendedEntityConfig.templateId === templateId
              );
              const templateConfig =
                recommendationConfig?.recommendedEntityConfig;

              if (
                !recommendationConfig ||
                !templateConfig ||
                templateConfig.__typename !== "RecommendedTemplateConfig"
              )
                return null;

              const hasNewerVersion =
                recommendation.recommendedEntity
                  .templateLatestPublishedVersionNumber >
                recommendation.recommendedEntity.templateVersionNumber;

              return (
                <RecommendationListItem
                  key={recommendation.id}
                  icon={<TemplateIcon />}
                  text={recommendation.teaserTitle}
                  link={`/templates/documents/${recommendation.recommendedEntity.templateId}/${recommendation.recommendedEntity.templateVersionNumber}`}
                  secondaryActionWidth={
                    ((hasNewerVersion ? 1 : 0) + (canEdit ? 3 : 0)) * 34
                  }
                  secondaryAction={
                    <Stack direction="row" spacing={0}>
                      {hasNewerVersion &&
                        (canEdit ? (
                          <Tooltip
                            title={t("Newer version available!", {
                              ns: "QuoteItem",
                            })}
                          >
                            <Box display="flex" component="span">
                              <ModalOpenButton
                                Modal={ConfirmModal}
                                modalProps={{
                                  title: t("Update to latest version", {
                                    ns: "QuoteItem",
                                  }),
                                  text: t(
                                    "Are you sure you want to update this template to the latest version?",
                                    {
                                      ns: "QuoteItem",
                                    }
                                  ),
                                  handleConfirm: async () => {
                                    assert(
                                      recommendation.recommendedEntity
                                        .__typename === "RecommendedTemplate",
                                      "Expected recommendedEntity to be a RecommendedTemplate"
                                    );
                                    await modifyRecommendedTemplate(
                                      templateId,
                                      {
                                        templateVersionNumber:
                                          recommendation.recommendedEntity
                                            .templateLatestPublishedVersionNumber,
                                      }
                                    );
                                    enqueueSnackbar(
                                      t(
                                        "Template was updated to the latest version",
                                        {
                                          ns: "QuoteItem",
                                        }
                                      )
                                    );
                                  },
                                }}
                              >
                                <IconButton
                                  color="warning"
                                  size="small"
                                  disabled={modifyLoading}
                                >
                                  <WarningAmberIcon />
                                </IconButton>
                              </ModalOpenButton>
                            </Box>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={t("Newer version available!", {
                              ns: "QuoteItem",
                            })}
                          >
                            <Box display="flex" component="span">
                              <IconButton color="warning" size="small">
                                <WarningAmberIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        ))}
                      {canEdit && (
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={async () => {
                            await setRecommendedTemplateRank(
                              templateId,
                              rank - 1
                            );
                          }}
                          disabled={
                            recommendation === recommendations[0] ||
                            setRankLoading ||
                            setRankTypeLoading
                          }
                        >
                          <KeyboardArrowUpIcon />
                        </IconButton>
                      )}
                      {canEdit && (
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={async () => {
                            await setRecommendedTemplateRank(
                              templateId,
                              rank + 1
                            );
                          }}
                          disabled={
                            recommendation ===
                              recommendations[recommendations.length - 1] ||
                            setRankLoading ||
                            setRankTypeLoading
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      )}
                      {canEdit && (
                        <ModalOpenButton
                          Modal={EditRecommendedTemplateModal}
                          modalProps={{
                            docId: item.docId,
                            itemId: item.id,
                            recommendation,
                            recommendationConfig,
                            recommendedTemplateConfig: templateConfig,
                            allowedItemTypes,
                            handleComplete: async config => {
                              if (config) {
                                await modifyRecommendedTemplate(templateId, {
                                  ...config,
                                  bindProps: config.bindProps
                                    ? config.bindProps.map(prop => ({
                                        destKey: prop.destKey,
                                        sourceKey: prop.sourceKey,
                                      }))
                                    : undefined,
                                });
                              } else {
                                await removeRecommendedTemplate(templateId);
                              }
                            },
                          }}
                        >
                          <IconButton
                            size="small"
                            color="primary"
                            disabled={modifyLoading || removeLoading}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </ModalOpenButton>
                      )}
                    </Stack>
                  }
                />
              );
            } else if (
              recommendation.recommendedEntity.__typename ===
              "RecommendedTemplateType"
            ) {
              const templateTypeId =
                recommendation.recommendedEntity.templateTypeId;
              const recommendationConfig = recommendationsConfig.find(
                config =>
                  config.recommendedEntityConfig.__typename ===
                    "RecommendedTemplateTypeConfig" &&
                  config.recommendedEntityConfig.templateTypeId ===
                    templateTypeId
              );
              const templateTypeConfig =
                recommendationConfig?.recommendedEntityConfig;

              if (
                !recommendationConfig ||
                !templateTypeConfig ||
                templateTypeConfig.__typename !==
                  "RecommendedTemplateTypeConfig"
              )
                return null;

              return (
                <RecommendationListItem
                  key={recommendation.id}
                  icon={<TemplateTypeIcon />}
                  text={recommendation.teaserTitle}
                  link={`/templates/types/${recommendation.recommendedEntity.templateTypeId}`}
                  secondaryActionWidth={(canEdit ? 3 : 0) * 34}
                  secondaryAction={
                    canEdit ? (
                      <Stack direction="row" spacing={0}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            setRecommendedTemplateTypeRank(
                              templateTypeId,
                              recommendation.rank - 1
                            );
                          }}
                          disabled={
                            recommendation === recommendations[0] ||
                            setRankLoading ||
                            setRankTypeLoading
                          }
                        >
                          <KeyboardArrowUpIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            setRecommendedTemplateTypeRank(
                              templateTypeId,
                              recommendation.rank + 1
                            );
                          }}
                          disabled={
                            recommendation ===
                              recommendations[recommendations.length - 1] ||
                            setRankLoading ||
                            setRankTypeLoading
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                        <ModalOpenButton
                          Modal={EditRecommendedTemplateTypeModal}
                          modalProps={{
                            docId: item.docId,
                            itemId: item.id,
                            templateTypeId,
                            recommendation,
                            recommendationConfig,
                            recommendedTemplateTypeConfig: templateTypeConfig,
                            allowedItemTypes,
                            handleComplete: async config => {
                              if (config) {
                                await modifyRecommendedTemplateType(
                                  templateTypeId,
                                  config
                                );
                              } else {
                                await removeRecommendedTemplateType(
                                  templateTypeId
                                );
                              }
                            },
                          }}
                        >
                          <IconButton
                            size="small"
                            color="primary"
                            disabled={modifyTypeLoading || removeTypeLoading}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </ModalOpenButton>
                      </Stack>
                    ) : null
                  }
                />
              );
            } else {
              assertNever(recommendation.recommendedEntity);
            }
          })}
        </List>
      ) : (
        <Stack padding={2} alignItems="center" justifyContent={"center"}>
          <Typography color={theme => theme.palette.grey[600]} variant="body2">
            {t("No recommendations", { ns: "QuoteItem" })}
          </Typography>
        </Stack>
      )}
    </CardContainer>
  );
};

function useRecommendedTemplateMutations(docId: string, itemId: string) {
  const client = useApolloClient();
  const [addItemRecommendedTemplate, { loading: addLoading }] =
    useAddRecommendedTemplateMutation({
      client,
    });
  const addRecommendedTemplate = async (
    templateId: string,
    templateVersionNumber: number
  ) => {
    await addItemRecommendedTemplate({
      client,
      variables: {
        input: {
          docId,
          itemId,
          templateId,
          templateVersionNumber,
          bindProps: [],
          eligibleIfExpr: "true",
          includeIfExpr: "false",
        },
      },
    });
  };

  const [modifyItemRecommendedTemplate, { loading: modifyLoading }] =
    useModifyRecommendedTemplateMutation({
      client,
    });
  const modifyRecommendedTemplate = async (
    templateId: string,
    values: ModifyItemRecommendedTemplateValuesInput
  ) => {
    await modifyItemRecommendedTemplate({
      client,
      variables: {
        input: {
          docId,
          itemId,
          templateId,
          values,
        },
      },
    });
  };

  const [removeItemRecommendedTemplate, { loading: removeLoading }] =
    useRemoveRecommendedTemplateMutation({
      client,
    });
  const removeRecommendedTemplate = async (templateId: string) => {
    await removeItemRecommendedTemplate({
      client,
      variables: {
        input: {
          docId,
          itemId,
          templateId,
        },
      },
    });
  };

  const [setItemRecommendedTemplateRank, { loading: setRankLoading }] =
    useSetRecommendedTemplateRankMutation({
      client,
    });
  const setRecommendedTemplateRank = async (
    templateId: string,
    rank: number
  ) => {
    await setItemRecommendedTemplateRank({
      client,
      variables: {
        input: {
          docId,
          itemId,
          templateId,
          rank,
        },
      },
    });
  };

  return {
    addRecommendedTemplate,
    modifyRecommendedTemplate,
    removeRecommendedTemplate,
    setRecommendedTemplateRank,
    addLoading,
    modifyLoading,
    removeLoading,
    setRankLoading,
  };
}

function useRecommendedTemplateTypeMutations(docId: string, itemId: string) {
  const client = useApolloClient();
  const [addItemRecommendedTemplateType, { loading: addTypeLoading }] =
    useAddRecommendedTemplateTypeMutation({
      client,
    });
  const addRecommendedTemplateType = async (templateTypeId: string) => {
    await addItemRecommendedTemplateType({
      client,
      variables: {
        input: {
          docId,
          itemId,
          templateTypeId,
          templateSearchFilterDefinitions: {
            propertyFilterDefinitions: [],
          },
          templateSearchFilterExpressions: { propertyFilterExpressions: [] },
          templateSearchSortingDefinitions: [],
          eligibleIfExpr: "true",
          includeIfExpr: "false",
        },
      },
    });
  };

  const [modifyItemRecommendedTemplateType, { loading: modifyTypeLoading }] =
    useModifyRecommendedTemplateTypeMutation({
      client,
    });
  const modifyRecommendedTemplateType = async (
    templateTypeId: string,
    values: ModifyItemRecommendedTemplateTypeValuesInput
  ) => {
    await modifyItemRecommendedTemplateType({
      client,
      variables: {
        input: {
          docId,
          itemId,
          templateTypeId,
          values,
        },
      },
    });
  };

  const [removeItemRecommendedTemplateType, { loading: removeTypeLoading }] =
    useRemoveRecommendedTemplateTypeMutation({
      client,
    });
  const removeRecommendedTemplateType = async (templateTypeId: string) => {
    await removeItemRecommendedTemplateType({
      client,
      variables: {
        input: { docId, itemId, templateTypeId },
      },
    });
  };

  const [setItemRecommendedTemplateTypeRank, { loading: setRankTypeLoading }] =
    useSetRecommendedTemplateTypeRankMutation({
      client,
    });
  const setRecommendedTemplateTypeRank = async (
    templateTypeId: string,
    rank: number
  ) => {
    await setItemRecommendedTemplateTypeRank({
      client,
      variables: {
        input: { docId, itemId, templateTypeId, rank },
      },
    });
  };

  return {
    addRecommendedTemplateType,
    modifyRecommendedTemplateType,
    removeRecommendedTemplateType,
    setRecommendedTemplateTypeRank,
    addTypeLoading,
    modifyTypeLoading,
    removeTypeLoading,
    setRankTypeLoading,
  };
}
