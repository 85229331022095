import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { debounce } from "lodash";
import React from "react";
import {
  TemplateTypeSelect_TemplateTypeFragment,
  useTemplateTypeSelectSearchTemplateTypesLazyQuery,
} from "./TemplateTypeSelect.generated";

const LIMIT = 25;

interface Props {
  templateTypeId: string | null;
  inputLabel?: string;
  onChange: (
    templateType: TemplateTypeSelect_TemplateTypeFragment | null
  ) => void;
  required?: boolean;
  disabled?: boolean;
}

export const TemplateTypeSelect = ({
  templateTypeId,
  inputLabel,
  required = false,
  disabled = false,
  onChange,
}: Props) => {
  const { t } = useTranslate(["TemplateTypes", "Global"]);
  const [inputValue, setInputValue] = React.useState("");

  const { templateTypes, refetch, totalCount, debouncedRefetch, loading } =
    useTemplateTypes(templateTypeId, inputValue, setInputValue);

  return (
    <Autocomplete
      placeholder={t("Type to search", {
        ns: "Global",
      })}
      inputLabel={
        inputLabel ??
        t("Template Type", {
          ns: "TemplateTypes",
        })
      }
      inputValue={inputValue}
      options={[
        ...templateTypes,
        ...(totalCount > templateTypes.length
          ? [
              {
                title: t("Type to see more...", { ns: "Global" }),
                id: "__type_to_see_more__" as const,
              },
            ]
          : []),
      ]}
      getOptionLabel={option =>
        "__typename" in option
          ? `${option.title} (${option.key})`
          : option.title
      }
      getOptionDisabled={option => option.id === "__type_to_see_more__"}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
          debouncedRefetch(value);
          if (templateTypeId) onChange(null);
        }
      }}
      value={
        templateTypes.find(
          templateType => templateType.id === templateTypeId
        ) ?? null
      }
      onChange={value => {
        if (value && "__typename" in value) {
          setInputValue(value.title);
          onChange(value);
        } else if (!value) {
          setInputValue("");
          onChange(null);
        }
      }}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      loading={loading}
      required={required}
      disabled={disabled}
    />
  );
};

function useTemplateTypes(
  templateTypeId: string | null,
  inputValue: string,
  setInputValue: (value: string) => void
) {
  const client = useApolloClient();
  const [fetchQuery, query] = useTemplateTypeSelectSearchTemplateTypesLazyQuery(
    {
      client,
      variables: {
        limit: LIMIT,
        offset: 0,
      },
      fetchPolicy: "network-only",
    }
  );

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          offset: 0,
          searchTerm: value,
        },
      });
    },
    [fetchQuery]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (templateTypeId) {
        const result = await fetchQuery({
          variables: {
            limit: 1,
            offset: 0,
            templateTypeIds: [templateTypeId],
          },
        });
        const option = getDataOrNull(result.data?.searchTemplateTypes)
          ?.edges?.[0]?.node;
        if (option && option.id === templateTypeId)
          setInputValue(`${option.title} (${option.key})`);
      } else {
        await fetchQuery({
          variables: {
            limit: LIMIT,
            offset: 0,
            searchTerm: inputValueRef.current,
          },
        });
      }
    })();
  }, [templateTypeId, fetchQuery, setInputValue]);

  const templateTypes =
    getDataOrNull(
      (query.data ?? query.previousData)?.searchTemplateTypes
    )?.edges?.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.searchTemplateTypes)
      ?.totalCount ?? 0;

  return {
    templateTypes,
    refetch,
    totalCount,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.searchTerm &&
          query?.variables?.searchTerm !== inputValue
      ),
  };
}
