import { Capacitor } from "@capacitor/core";
import { isImage, processAttachment } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, useFormikContext } from "formik";
import { findIndex } from "lodash";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { useLatest } from "react-use";
import { v4 } from "uuid";
import * as Yup from "yup";
import { LocalDndProvider } from "../../../../common/dnd";
import { CheckboxField } from "../../../commons/form-fields/CheckboxField";
import { FormattedFloatOptionalField } from "../../../commons/form-fields/FormattedFloatOptionalField";
import { TextField } from "../../../commons/form-fields/TextField";
import {
  AttachmentPhotoUploaderWithEditor,
  AttachmentPhotoUploaderWithEditorRef,
} from "../../attachments/AttachmentPhotoUploaderWithEditor";
import { AttachmentUploader } from "../../attachments/AttachmentUploader";
import { Attachment, getRotatedUrl } from "../../attachments/helpers";
import { PropertyGalleryBox } from "../boxes/PropertyGalleryBox";

type AllowedValue = {
  key: string;
  value: number | string | null;
  media: Attachment | null;
};

export interface AllowedValuesFormValues {
  defineAllowedValues: boolean;
  allowedValues: AllowedValue[];
}

interface Props {
  type: "TEXT" | "NUMBER";
  disabled?: boolean;
}

export const AllowedValuesField = (props: Props) => {
  return (
    <LocalDndProvider>
      <AllowedValuesFieldComponent {...props} />
    </LocalDndProvider>
  );
};

const AllowedValuesFieldComponent = ({ type, disabled }: Props) => {
  const { t } = useTranslate(["Global", "QuoteItem"]);
  const formikProps = useFormikContext<AllowedValuesFormValues>();

  const [, drop] = useDrop({ accept: "field" });
  const [draggedValueKey, setDraggedValueKey] = React.useState<string | null>(
    null
  );

  return (
    <>
      <CheckboxField
        name={"defineAllowedValues"}
        label={t("Define allowed values", {
          ns: "QuoteItem",
        })}
        disabled={disabled}
      />
      {formikProps.values.defineAllowedValues && (
        <FieldArray
          name="allowedValues"
          render={arrayHelpers => (
            <Stack
              direction="column"
              alignSelf={"stretch"}
              spacing={1}
              paddingLeft={4}
            >
              <Stack
                direction="column"
                alignSelf={"stretch"}
                spacing={1}
                ref={drop}
              >
                {formikProps.values.allowedValues.map((value, index) => (
                  <AllowedValueField
                    type={type}
                    name="allowedValues"
                    values={formikProps.values.allowedValues}
                    value={value}
                    key={`value-${value.key}`}
                    index={index}
                    disabled={disabled}
                    canRemove={formikProps.values.allowedValues.length >= 1}
                    draggedValueKey={draggedValueKey}
                    setDraggedValueKey={setDraggedValueKey}
                  />
                ))}
              </Stack>
              <Button
                startIcon={<AddIcon />}
                size="small"
                variant="text"
                color="secondary"
                sx={{ alignSelf: "flex-start" }}
                onClick={() => {
                  arrayHelpers.push({ value: null, media: null, key: v4() });
                }}
                disabled={disabled}
              >
                {t("Add value", { ns: "Global" })}
              </Button>
            </Stack>
          )}
        />
      )}
    </>
  );
};

function AllowedValueField({
  type,
  name,
  value,
  values,
  index,
  disabled,
  canRemove,
  draggedValueKey,
  setDraggedValueKey,
}: {
  type: "TEXT" | "NUMBER";
  name: string;
  value: AllowedValue;
  values: AllowedValue[];
  index: number;
  disabled?: boolean;
  canRemove: boolean;
  draggedValueKey: string | null;
  setDraggedValueKey: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const { t } = useTranslate(["QuoteItem", "Global"]);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const formikProps = useFormikContext<AllowedValuesFormValues>();

  const attachmentInputRef = React.useRef<HTMLInputElement>(null);
  const attachmentPhotoUploaderRef =
    React.useRef<AttachmentPhotoUploaderWithEditorRef>(null);

  const stateRef = useLatest({
    formikProps,
    draggedValueKey,
    setDraggedValueKey,
  });

  const [, drop] = useDrop<
    { valueKey: string; type: string },
    void,
    { isDragging: boolean }
  >({
    accept: "field",
    canDrop: () => false,
    hover({ valueKey: draggedKey }, monitor) {
      if (stateRef.current.draggedValueKey !== draggedKey) {
        stateRef.current.setDraggedValueKey(draggedKey);
      }
      if (draggedKey !== value.key) {
        const dragIndex = findIndex(values, v => v.key === draggedKey);
        const hoverIndex = findIndex(values, v => v.key === value.key);
        const newValues = [...values];
        const draggedValue = newValues.splice(dragIndex, 1)[0];
        newValues.splice(hoverIndex, 0, draggedValue);
        stateRef.current.formikProps.setFieldValue(name, newValues);
      }
    },
  });
  const [{ isDragging }, drag, dragPreview] = useDrag<
    { valueKey: string; type: string },
    void,
    { isDragging: boolean }
  >({
    item: { type: "field", valueKey: value.key },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      stateRef.current.setDraggedValueKey(null);
      // const didDrop = monitor.didDrop();
    },
  });

  return (
    <Stack
      direction="row"
      spacing={1}
      key={index}
      justifyContent="space-between"
      alignItems="center"
      sx={{ opacity: isDragging ? 0.25 : 1, userSelect: "none" }}
      ref={node => dragPreview(node as HTMLDivElement | null)}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        flexGrow={1}
        flexShrink={1}
      >
        <Box
          display="flex"
          height="48px"
          alignItems="center"
          ref={node => drop(drag(node as HTMLDivElement | null))}
          sx={{ cursor: "move" }}
        >
          <DragIndicatorIcon color="action" />
        </Box>

        {value.media && (
          <PropertyGalleryBox
            pictures={[value.media].map(processAttachment).filter(isImage)}
            flexGrow={0}
            flexShrink={0}
            width="80px"
            height="60px"
            imageHeight={60}
            aspectRatio="80 / 60"
            notInStack
            handleRotate={(attachment, direction) => {
              formikProps.setFieldValue(
                `${name}.${index}.media.url`,
                getRotatedUrl(attachment.url, direction)
              );
            }}
            handleDelete={() => {
              formikProps.setFieldValue(`${name}.${index}.media`, null);
            }}
          />
        )}
        {type === "NUMBER" ? (
          <FormattedFloatOptionalField
            name={`${name}.${index}.value`}
            label={t("Value", {
              ns: "Global",
            })}
            disabled={disabled}
            autoFocus={!draggedValueKey} // doesn't work well with dragging
          />
        ) : (
          <TextField
            name={`${name}.${index}.value`}
            label={t("Value", {
              ns: "Global",
            })}
            disabled={disabled}
            autoFocus={!draggedValueKey} // doesn't work well with dragging
          />
        )}
      </Stack>
      <Stack direction="row" spacing={0.5} alignItems="center">
        {!value.media && (
          <>
            <IconButton
              size="small"
              color="secondary"
              disabled={disabled || isSubmitting}
              onClick={async () => {
                if (disabled) return;

                if (Capacitor.isNativePlatform()) {
                  attachmentPhotoUploaderRef.current?.start();
                } else {
                  attachmentInputRef.current?.click();
                }
              }}
            >
              {isSubmitting ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                <AddAPhotoIcon />
              )}
            </IconButton>
            {Capacitor.isNativePlatform() && (
              <AttachmentPhotoUploaderWithEditor
                ref={attachmentPhotoUploaderRef}
                onStart={() => {
                  setIsSubmitting(true);
                }}
                onAttachment={attachment => {
                  if (attachment) {
                    formikProps.setFieldValue(
                      `${name}.${index}.media`,
                      attachment
                    );
                  }
                  setIsSubmitting(false);
                }}
              />
            )}
            <AttachmentUploader
              innerRef={attachmentInputRef}
              accept={"image/*,.heic,.heif"}
              multiple={false}
              onStart={() => {
                setIsSubmitting(true);
              }}
              onComplete={attachment => {
                if (attachment) {
                  formikProps.setFieldValue(
                    `${name}.${index}.media`,
                    attachment
                  );
                }
                setIsSubmitting(false);
              }}
            />
          </>
        )}
        <IconButton
          size="small"
          color="primary"
          disabled={!canRemove || disabled}
          onClick={() => {
            formikProps.setFieldValue(
              name,
              values.filter(v => v !== value)
            );
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}

export const useAllowedValuesFieldValidationSchema = () => {
  const { t } = useTranslate(["QuoteItem", "Global"]);

  return React.useMemo(
    () => ({
      defineAllowedValues: Yup.boolean().required(),
      allowedValues: Yup.array().when("defineAllowedValues", {
        is: true,
        then: Yup.array()
          .when("type", {
            is: "NUMBER",
            then: Yup.array()
              .of(
                Yup.object().shape({
                  value: Yup.number()
                    .nullable()
                    .required()
                    .label(
                      t("Value", {
                        ns: "Global",
                      })
                    )
                    .test(
                      "unique",
                      t("Value must be unique", {
                        ns: "Global",
                      }),
                      function (value: number | null | undefined) {
                        // @ts-ignore
                        const allowedValues = (this.from as any)[1].value
                          .allowedValues as { value: number | null }[];
                        const sameValues = allowedValues.filter(
                          v => v.value === value
                        );
                        return sameValues.length <= 1;
                      }
                    ),
                  media: Yup.object().nullable(),
                })
              )
              .min(1),
          })
          .when("type", {
            is: "TEXT",
            then: Yup.array()
              .of(
                Yup.object().shape({
                  value: Yup.string()
                    .nullable()
                    .required()
                    .label(
                      t("Value", {
                        ns: "Global",
                      })
                    )
                    .test(
                      "unique",
                      t("Value must be unique", {
                        ns: "Global",
                      }),
                      function (value: string | null | undefined) {
                        // @ts-ignore
                        const allowedValues = (this.from as any)[1].value
                          .allowedValues as { value: string | null }[];
                        const sameValues = allowedValues.filter(
                          v => v.value === value
                        );
                        return sameValues.length <= 1;
                      }
                    ),
                  media: Yup.object().nullable(),
                })
              )
              .min(1),
          }),
      }),
    }),
    [t]
  );
};
