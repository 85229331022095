import { LabeledValue, ModalOpenButton } from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Stack,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ProductTypesSearchModal } from "../modals/ProductTypesSearchModal";

interface ProductType {
  id: string;
  key: string;
  label: string;
  typeSystem: "ETIM8";
}

interface Props {
  label?: string;
  valueLabel?: string;
  productType?: ProductType | null;
  handleChange: (productType: ProductType | null) => void;
  canEdit?: boolean;
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
}
export function PickProductTypeButton({
  label,
  valueLabel,
  productType,
  handleChange,
  canEdit = true,
  buttonProps,
  iconButtonProps,
}: Props) {
  const { t } = useTranslate("ProductOverview");

  if (!canEdit && !productType) return null;

  if (productType) {
    return (
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <LabeledValue label={valueLabel ?? t("Product type")}>
          {productType.label}
        </LabeledValue>
        {canEdit && (
          <ModalOpenButton
            Modal={ProductTypesSearchModal}
            modalProps={{
              handleProductTypeChoice: async (productType, handleClose) => {
                handleChange(productType);
                handleClose();
              },
            }}
          >
            <IconButton size="small" color="secondary" {...iconButtonProps}>
              <EditIcon />
            </IconButton>
          </ModalOpenButton>
        )}
        {canEdit && (
          <IconButton
            size="small"
            color="secondary"
            {...iconButtonProps}
            onClick={() => handleChange(null)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="flex-start">
      <LabeledValue label={valueLabel ?? t("Product type")}>
        <ModalOpenButton
          Modal={ProductTypesSearchModal}
          modalProps={{
            handleProductTypeChoice: async (productType, handleClose) => {
              handleChange(productType);
              handleClose();
            },
          }}
        >
          <Button
            size="small"
            variant="text"
            color="secondary"
            {...buttonProps}
          >
            {label ?? t("Assign product type")}
          </Button>
        </ModalOpenButton>
      </LabeledValue>
    </Stack>
  );
}
