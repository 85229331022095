import { useApolloClient } from "@apollo/client";
import { AttachmentInput } from "@msys/attachment";
import {
  CollapseSection,
  ellipsisStyle,
  LoadingSpinner,
  MenuButton,
  useDownloadFile,
} from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, Form, useFormikContext } from "formik";
import { groupBy } from "lodash";
import React from "react";
import * as Yup from "yup";
import {
  Attachment,
  EmailTemplateContext,
} from "../../../clients/graphqlTypes";
import { useFeature } from "../../../common/FeatureFlags";
import { AutocompleteField } from "../../commons/form-fields/AutocompleteField";
import { type SelectOption } from "../../commons/form-fields/SelectField";
import { TextField } from "../../commons/form-fields/TextField";
import { LexicalRichTextEditorWithHtml } from "../../commons/rich-text-editor/LexicalRichTextEditorWIthHtml";
import { FilesInputField } from "../attachments/FilesInputField";
import {
  CrmSendEmailForm_AvailableTemplateFragment,
  useCrmSendEmailMutation,
  useEmailTemplateValuesLazyQuery,
  useRequestCrmEmailAttachmentUploadUrlMutation,
  useUseCrmSendEmailQuery,
} from "./CrmSendEmailForm.generated";

export interface UrlAttachment {
  attachment: {
    filename: string;
    mimeType: string;
    url: string;
  };
  group?: string;
  notRemovable?: boolean;
}
export type RecipientOption = {
  crmCompanyId: string;
  crmPersonId: string | null;
} & SelectOption;
export type EmailContext =
  | { type: "PROJECT"; id: string }
  | { type: "QUOTE"; id: string }
  | { type: "REQUIREMENT"; id: string };
export interface FormValues {
  recipient: RecipientOption | null;
  templateId: string;
  subject: string;
  body: string;
  templateAttachments: Attachment[];
  urlAttachments: UrlAttachment[];
  attachments: File[];
}

interface Props {
  recipientOptions: RecipientOption[];
  additionalFields?: React.ReactNode;
  formId: string;
  availableTemplates: CrmSendEmailForm_AvailableTemplateFragment[];
  context: EmailContext | null;
}

export function CrmSendEmailForm({
  recipientOptions,
  additionalFields,
  formId,
  availableTemplates,
  context,
}: Props) {
  const crmEmailContentDebug = useFeature("CrmEmailContentDebug");
  const { t } = useTranslate([
    "CrmOrganisations",
    "Email",
    "Global",
    "FilesBoxTable",
  ]);
  const { downloadFileAsFile } = useDownloadFile();
  const formikProps = useFormikContext<FormValues>();
  const getEmailTemplateValues = useEmailTemplateValues({ context });
  const [isLoading, setIsLoading] = React.useState(false);

  const updateFormWithTemplateValues = React.useCallback(
    (templateId: string, recipient: RecipientOption) => {
      setIsLoading(true);
      const setFieldValue = formikProps.setFieldValue;

      getEmailTemplateValues(recipient, templateId)
        .then(values => {
          setFieldValue("subject", values.subject);
          setFieldValue("body", values.content);
          setFieldValue("templateAttachments", values.attachments);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [getEmailTemplateValues, formikProps.setFieldValue]
  );

  React.useEffect(() => {
    const recipient = formikProps.values.recipient;
    const templateId = formikProps.values.templateId;

    if (recipient && templateId) {
      updateFormWithTemplateValues(templateId, recipient);
    }
  }, [
    formikProps.values.recipient,
    formikProps.values.templateId,
    updateFormWithTemplateValues,
  ]);

  return (
    <Form id={formId}>
      <Stack direction={"column"} spacing={1}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <AutocompleteField
            name={"recipient"}
            inputLabel={t("Email", { ns: "Global" })}
            options={recipientOptions}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            isRequired
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.key}>
                  {option.label}
                </li>
              );
            }}
            disableClearable
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <TextField
            name={"subject"}
            label={t("Subject", { ns: "Email" })}
            required
            clearable
          />
          <MenuButton
            Icon={
              <Tooltip title={t("Apply template", { ns: "Email" })}>
                <FindReplaceIcon />
              </Tooltip>
            }
            buttonProps={{ size: "medium" }}
            disabled={!formikProps.values.recipient}
          >
            {availableTemplates.map(template => (
              <MenuItem
                key={template.id}
                onClick={() => {
                  if (formikProps.values.templateId === template.id) {
                    updateFormWithTemplateValues(
                      template.id,
                      formikProps.values.recipient!
                    );
                  }
                  formikProps.setFieldValue("templateId", template.id);
                }}
              >
                {template.description}
              </MenuItem>
            ))}
          </MenuButton>
        </Stack>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <LexicalRichTextEditorWithHtml
              placeholder={t("Text", { ns: "OrganisationSettings" })}
              initialHtmlValue={formikProps.values.body}
              handleChange={value => {
                formikProps.setFieldValue("body", value);
              }}
              debug={crmEmailContentDebug}
            />

            {additionalFields && <Box>{additionalFields}</Box>}

            {formikProps.values.templateAttachments.length > 0 ? (
              <CollapseSection
                title={t("Files from template", {
                  ns: "QuoteSend",
                })}
              >
                <List disablePadding>
                  <FieldArray name={"templateAttachments"}>
                    {({ remove }) =>
                      formikProps.values.templateAttachments.map(
                        (attachment, index) => (
                          <AttachmentRow
                            key={attachment.url}
                            filename={attachment.title}
                            url={attachment.url}
                            onRemove={() => {
                              remove(index);
                            }}
                          />
                        )
                      )
                    }
                  </FieldArray>
                </List>
              </CollapseSection>
            ) : null}

            {formikProps.values.urlAttachments.length > 0 ? (
              <FieldArray name={"urlAttachments"}>
                {({ remove }) =>
                  Object.entries(
                    groupBy(
                      formikProps.values.urlAttachments,
                      urlAttachment =>
                        urlAttachment.group ??
                        t("Attachments", { ns: "Global" })
                    )
                  ).map(([group, urlAttachments]) => (
                    <CollapseSection key={group} title={group}>
                      <List disablePadding>
                        {urlAttachments.map(urlAttachment => (
                          <AttachmentRow
                            key={urlAttachment.attachment.url}
                            filename={urlAttachment.attachment.filename}
                            url={urlAttachment.attachment.url}
                            onRemove={
                              urlAttachment.notRemovable
                                ? undefined
                                : () => {
                                    remove(
                                      formikProps.values.urlAttachments.findIndex(
                                        v => v === urlAttachment
                                      )
                                    );
                                  }
                            }
                          />
                        ))}
                      </List>
                    </CollapseSection>
                  ))
                }
              </FieldArray>
            ) : null}

            {formikProps.values.attachments.length > 0 ? (
              <FieldArray name={"attachments"}>
                {({ remove }) => (
                  <CollapseSection
                    title={t("Additional attachments", {
                      ns: "FilesBoxTable",
                    })}
                  >
                    <List disablePadding>
                      {formikProps.values.attachments.map((file, index) => (
                        <FileRow
                          key={`${file.name}-${index}`}
                          filename={file.name}
                          onClick={async () => {
                            await downloadFileAsFile(file, file.name, true);
                          }}
                          onRemove={() => {
                            remove(index);
                          }}
                        />
                      ))}
                    </List>
                  </CollapseSection>
                )}
              </FieldArray>
            ) : null}
            <Stack direction={"row"}>
              <FilesInputField
                name="attachments"
                button={
                  <Button
                    size="extra-small"
                    startIcon={<FileUploadIcon />}
                    variant="outlined"
                    color="secondary"
                  >
                    {t("Choose files", { ns: "FilesBoxTable" })}
                  </Button>
                }
              />
            </Stack>
          </>
        )}
      </Stack>
    </Form>
  );
}

function AttachmentRow({
  filename,
  url,
  onRemove,
}: {
  filename: string;
  url: string;
  onRemove?: () => Promise<void> | void;
}) {
  const { t } = useTranslate(["FilesBoxTable"]);

  return (
    <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
      <ListItem
        dense
        component={Link}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={ellipsisStyle}
        sx={{ flexShrink: 1, width: "auto" }}
      >
        <ListItemIcon
          sx={theme => ({
            minWidth: theme.layout.listItemMinWidth.xs,
            display: "flex",
            alignItems: "center",
          })}
        >
          <DescriptionIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          style={ellipsisStyle}
          primary={filename}
          primaryTypographyProps={{ style: ellipsisStyle }}
        />
      </ListItem>
      {onRemove && (
        <Tooltip title={t("Delete File", { ns: "FilesBoxTable" })!}>
          <IconButton color="primary" onClick={onRemove} size="small">
            <DeleteIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}

function FileRow({
  filename,
  onClick,
  onRemove,
}: {
  filename: string;
  onClick: () => void;
  onRemove: () => Promise<void> | void;
}) {
  const { t } = useTranslate(["FilesBoxTable"]);

  return (
    <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
      <ListItem
        dense
        style={ellipsisStyle}
        sx={{ flexShrink: 1, width: "auto" }}
        onClick={onClick}
      >
        <ListItemIcon
          sx={theme => ({
            minWidth: theme.layout.listItemMinWidth.xs,
            display: "flex",
            alignItems: "center",
          })}
        >
          <DescriptionIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          style={ellipsisStyle}
          primary={filename}
          primaryTypographyProps={{ style: ellipsisStyle }}
        />
      </ListItem>
      <Tooltip title={t("Delete File", { ns: "FilesBoxTable" })!}>
        <IconButton color="primary" onClick={onRemove} size="small">
          <DeleteIcon fontSize={"small"} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

function useEmailTemplateValues({ context }: { context: EmailContext | null }) {
  const client = useApolloClient();
  const [fetchEmailTemplateTexts] = useEmailTemplateValuesLazyQuery({
    client,
    // fetchPolicy: "no-cache",
  });

  const getEmailtemplateValues = React.useCallback(
    async function handleRecipientChange(
      recipient: RecipientOption,
      templateId: string
    ) {
      const response = await fetchEmailTemplateTexts({
        variables: {
          templateId: templateId,
          crmCompanyId: recipient.crmCompanyId,
          crmPersonId: recipient.crmPersonId,
          context,
        },
      });

      const values =
        response.data?.organisationEmailTemplateValuesWithReplacements;

      return { ...values };
    },
    [fetchEmailTemplateTexts, context]
  );

  return getEmailtemplateValues;
}

export function useCrmSendEmail({
  recipient,
  urlAttachments,
  context,
  emailTemplateContext,
  getAttachmentsOnSubmit,
  refetchQueries,
}: {
  recipient?: RecipientOption;
  urlAttachments?: UrlAttachment[];
  context: EmailContext | null;
  emailTemplateContext?: EmailTemplateContext;
  getAttachmentsOnSubmit?: () => Promise<AttachmentInput[]>;
  refetchQueries?: string[];
}) {
  const { t } = useTranslate(["Email", "Global"]);

  const client = useApolloClient();
  const query = useUseCrmSendEmailQuery({
    client,
    variables: { emailTemplateContext },
  });
  const emailTemplates = React.useMemo(
    () => query.data?.organisationEmailTemplates ?? [],
    [query.data?.organisationEmailTemplates]
  );

  const [requestAttachmentUploadUrl] =
    useRequestCrmEmailAttachmentUploadUrlMutation({
      client,
    });
  const [sendCrmEmail] = useCrmSendEmailMutation({
    client,
    refetchQueries,
  });

  const initialValues = React.useMemo((): FormValues => {
    return {
      recipient: recipient ?? null,
      subject: "",
      body: "",
      templateAttachments: [],
      urlAttachments: urlAttachments ?? [],
      attachments: [],
      templateId: emailTemplates.length === 1 ? emailTemplates[0].id : "",
    };
  }, [recipient, emailTemplates, urlAttachments]);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        recipient: Yup.object()
          .shape({
            crmCompanyId: Yup.string()
              .label(t("Email", { ns: "Global" }))
              .required(),
            crmPersonId: Yup.string()
              .label(t("Email", { ns: "Global" }))
              .nullable(),
          })
          .label(t("Email", { ns: "Global" }))
          .nullable()
          .required(),
        subject: Yup.string()
          .label(t("Subject", { ns: "Email" }))
          .required(),
        body: Yup.string()
          .label(t("Body", { ns: "Email" }))
          .required(),
      }),
    [t]
  );

  const handleSendEmail = async (values: FormValues) => {
    if (!values.recipient || !values.recipient.crmCompanyId) return;

    const additionalAttachments: AttachmentInput[] =
      (await getAttachmentsOnSubmit?.()) ?? [];

    const adHocAttachments = await Promise.all(
      values.attachments.map(async file => {
        const result = await requestAttachmentUploadUrl({
          variables: {
            input: {
              crmCompanyId: values.recipient!.crmCompanyId,
              crmPersonId: values.recipient!.crmPersonId,
              context,
              filename: file.name,
            },
          },
        });
        const uploadUrl =
          result.data?.requestCrmEmailAttachmentUploadUrl.uploadUrl;
        if (!uploadUrl) throw new Error("Upload url missing");

        await fetch(uploadUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Disposition": `attachment; filename="${file.name}"`,
          },
        }).catch(error => {
          console.error(error);
          throw error;
        });

        return { uploadUrl };
      })
    );

    await sendCrmEmail({
      variables: {
        input: {
          crmCompanyId: values.recipient.crmCompanyId,
          crmPersonId: values.recipient.crmPersonId,
          context: context ?? null,
          subject: values.subject,
          contentHtml: values.body,
          adHocAttachments,
          urlAttachments: [
            ...additionalAttachments.map(a => ({
              filename: a.title ?? "",
              mimeType: a.mimeType ?? "",
              url: a.url,
            })), // FIXME: these urls could be temporary urls like it already is for quote PDF
            ...(values.urlAttachments.map(
              urlAttachment => urlAttachment.attachment
            ) ?? []),
            ...values.templateAttachments.map(templateAttachment => ({
              filename: templateAttachment.title,
              mimeType: templateAttachment.mimeType,
              url: templateAttachment.url,
            })),
          ],
        },
      },
    });
  };

  return { emailTemplates, initialValues, validationSchema, handleSendEmail };
}
