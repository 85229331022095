import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  ListHeader,
  LoadingSpinner as LoadingIndicator,
  MediaCardItem,
  MediaListItem,
  Modal,
  useLocalStorageAsState,
  useScreenWidth,
} from "@msys/ui";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isUndefined } from "lodash";
import React from "react";
import {
  ProductTypesSearchModal_PimProductTypeFragment,
  useProductTypesSearchModalQuery,
} from "./ProductTypesSearchModal.generated";
import { CollectionView } from "../../../commons/hooks/useCollectionView";
import { ButtonCircleWrapper } from "../../../commons/button/ButtonCircleWrapper";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../constants";
import { ProductTypeOverviewModal } from "./ProductTypeOverviewModal";
import { SwitchCollectionViewButton } from "../../../commons/button/SwitchCollectionViewButton";
import { useProductTypesList } from "./useProductTypesList";
import { PimSearchProductTypesSortBy } from "../../../../clients/graphqlTypes";

const ALLOWED_VIEWS: CollectionView[] = ["gallery", "list", "table"];

interface Props {
  initialSearchValue?: string;
  handleProductTypeChoice: (
    productType: ProductTypesSearchModal_PimProductTypeFragment,
    handleClose: () => void
  ) => Promise<void> | void;
  handleClose: () => void;
  handleSearchValueChange?: (value: string) => void;
}

export const ProductTypesSearchModal = (props: Props) => {
  const { t } = useTranslate(["ProductTypesSearch"]);

  return (
    <Modal
      dialogProps={{
        maxWidth: "xl",
        PaperProps: { style: { height: "100%" } },
      }}
      handleClose={props.handleClose}
      title={t("Product type search", { ns: "ProductTypesSearch" })}
      alwaysVisible
    >
      <ProductTypesSearchModalComponent {...props} />
    </Modal>
  );
};

export const ProductTypesSearchModalComponent = ({
  initialSearchValue = "",
  handleProductTypeChoice,
  handleClose,
  handleSearchValueChange,
}: Props) => {
  const { t } = useTranslate([
    "ProductTypesSearch",
    "Global",
    "TemplatesSearch",
    "ProductTypes",
  ]);
  const { isMinTablet } = useScreenWidth();

  const [selected, setSelected] =
    React.useState<ProductTypesSearchModal_PimProductTypeFragment | null>(null);

  const { paginationModel, setPaginationModel, sorting, setSorting } =
    useProductTypesList({
      activeViewStorageKey: "product-types-search-modal",
      activeViewInitialDesktopValue: "table",
      activeViewInitialMobileValue: "list",
      initialFilters: {},
    });

  const [searchTerm, setSearchTerm] =
    React.useState<string>(initialSearchValue);

  const client = useApolloClient();
  const query = useProductTypesSearchModalQuery({
    client,
    variables: {
      searchTerm,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sorting,
    },
  });

  const [activeView, setActiveView] = useLocalStorageAsState<CollectionView>(
    "msys-productTypesListViewType",
    "table"
  );

  React.useEffect(() => {
    const totalCount = getDataOrNull(
      query.data?.pimSearchProductTypes
    )?.totalCount;
    if (
      !isUndefined(totalCount) &&
      totalCount < paginationModel.pageSize * paginationModel.page &&
      paginationModel.page > 0
    ) {
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }
  }, [query.data, paginationModel, setPaginationModel]);

  const items =
    getDataOrNull((query.data ?? query.previousData)?.pimSearchProductTypes)
      ?.productTypes ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.pimSearchProductTypes)
      ?.totalCount ?? 0;

  const renderProductTypeSelectButton = (
    productType: ProductTypesSearchModal_PimProductTypeFragment
  ) => {
    return (
      <IconButton
        color={"primary"}
        size={"small"}
        onClick={() => {
          handleProductTypeChoice(productType, handleClose);
        }}
        title={t("Select", {
          ns: "ProductTypesSearch",
        })}
        aria-label={t("Select", {
          ns: "ProductTypesSearch",
        })}
      >
        <TouchAppIcon />
      </IconButton>
    );
  };

  const renderItems = (
    items: ProductTypesSearchModal_PimProductTypeFragment[]
  ) => {
    switch (activeView) {
      case "list":
        if (query.loading) return <LoadingIndicator />;
        return items.length > 0 ? (
          <Stack
            direction="column"
            spacing={1}
            flex={1}
            overflow="auto"
            minHeight={0}
            sx={theme => ({
              marginRight: `-${theme.spacing(2)} !important`,
            })}
          >
            <Stack
              direction="column"
              spacing={1}
              overflow="auto"
              flex={1}
              minHeight={0}
              pr={2}
            >
              {items.map((productType, index) => {
                const Info = (
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      {productType.key}
                    </Stack>
                    {productType.labelSynonyms.length > 0 && (
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        {productType.labelSynonyms.join(", ")}
                      </Stack>
                    )}
                  </Typography>
                );

                return (
                  <MediaListItem
                    key={productType.id}
                    onClick={() => {
                      setSelected(productType);
                    }}
                    title={productType.label}
                    description={productType.description ?? undefined}
                    Info={Info}
                    ActionButton={
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        {renderProductTypeSelectButton(productType)}
                      </Stack>
                    }
                  />
                );
              })}
            </Stack>
            <TablePagination
              component="div"
              count={totalCount}
              page={paginationModel.page}
              onPageChange={(event, newPage) => {
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: newPage,
                });
              }}
              rowsPerPage={paginationModel.pageSize}
              onRowsPerPageChange={event => {
                setPaginationModel({
                  pageSize: parseInt(event.target.value, 10),
                  page: 0,
                });
              }}
              labelRowsPerPage={t("Rows per page:", {
                ns: "Global",
              })}
            />
          </Stack>
        ) : (
          <Box padding={2} display="flex" justifyContent="center">
            {t("There seems to be nothing here", {
              ns: "Global",
            })}
          </Box>
        );

      case "gallery":
        if (query.loading) return <LoadingIndicator />;
        return (
          <Stack
            direction="column"
            spacing={1}
            flex={1}
            overflow="auto"
            minHeight={0}
            sx={theme => ({
              marginRight: `-${theme.spacing(2)} !important`,
            })}
          >
            <Stack
              direction="column"
              spacing={1}
              overflow="auto"
              flex={1}
              minHeight={0}
              pr={2}
            >
              <Grid container spacing={isMinTablet ? 2 : 1}>
                {items.map((productType, index) => {
                  const Info = (
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        {productType.key}
                      </Stack>
                      {productType.labelSynonyms.length > 0 && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          {productType.labelSynonyms.join(", ")}
                        </Stack>
                      )}
                    </Typography>
                  );

                  return (
                    <Grid key={productType.id} item xs={6} md={6} lg={4}>
                      <MediaCardItem
                        key={productType.id}
                        onClick={() => {
                          setSelected(productType);
                        }}
                        title={productType.label}
                        description={productType.description ?? undefined}
                        Info={Info}
                        ActionButton={
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonCircleWrapper>
                              {renderProductTypeSelectButton(productType)}
                            </ButtonCircleWrapper>
                          </Stack>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
            <TablePagination
              component="div"
              count={totalCount}
              page={paginationModel.page}
              onPageChange={(event, newPage) => {
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: newPage,
                });
              }}
              rowsPerPage={paginationModel.pageSize}
              onRowsPerPageChange={event => {
                setPaginationModel({
                  pageSize: parseInt(event.target.value, 10),
                  page: 0,
                });
              }}
              labelRowsPerPage={t("Items per page:", {
                ns: "Global",
              })}
            />
          </Stack>
        );

      case "table":
        return (
          <DataGrid
            density="compact"
            loading={query.loading}
            hideFooter={totalCount === 0}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              setSelected(params.row);
            }}
            localeText={{
              noRowsLabel: t("There are no items to display", {
                ns: "Global",
              }),
              MuiTablePagination: {
                labelRowsPerPage: t("Rows per page", {
                  ns: "Global",
                }),
              },
            }}
            onSortModelChange={newModel => {
              setSorting(
                newModel.map(({ field, sort }) => ({
                  column: field as PimSearchProductTypesSortBy,
                  direction: sort ?? "asc",
                }))
              );
            }}
            columns={[
              {
                field: "checkbox",
                headerName: "",
                width: 50,
                sortable: false,
                resizable: false,
                renderCell: ({ row: productType }) =>
                  renderProductTypeSelectButton(productType),
              },
              {
                field: "label",
                headerName: t("Title", {
                  ns: "Templates",
                }),
                flex: 2,
                minWidth: 150,
                renderCell: ({
                  row: productType,
                }: {
                  row: ProductTypesSearchModal_PimProductTypeFragment;
                }) => productType.label,
              },
              {
                field: "key",
                headerName: t("Key", {
                  ns: "ProductTypes",
                }),
                flex: 2,
                minWidth: 100,
                renderCell: ({
                  row: productType,
                }: {
                  row: ProductTypesSearchModal_PimProductTypeFragment;
                }) => productType.key,
              },
              {
                field: "synonyms",
                headerName: t("Synonyms", {
                  ns: "ProductTypes",
                }),
                sortable: false,
                flex: 5,
                minWidth: 150,
                renderCell: ({
                  row: productType,
                }: {
                  row: ProductTypesSearchModal_PimProductTypeFragment;
                }) => productType.labelSynonyms.join(", "),
              },
            ]}
            rows={items}
            paginationModel={paginationModel}
            onPaginationModelChange={newPaginationModel => {
              setPaginationModel(newPaginationModel);
            }}
            disableColumnFilter
            pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
            rowCount={totalCount}
          />
        );
    }
  };

  return (
    <Stack alignItems={"stretch"} height="100%" spacing={2} direction="row">
      <Stack
        direction={"column"}
        height="100%"
        spacing={1}
        flex={1}
        minWidth={0}
      >
        <ProductTypesSearchModalListHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          activeView={activeView}
          setActiveView={setActiveView}
          handleSearchValueChange={handleSearchValueChange}
        />
        {query.error && <ErrorMessage message={query.error.message} />}
        {renderItems(items)}
      </Stack>
      {selected && (
        <ProductTypeOverviewModal
          productTypeId={selected.id}
          handleClose={() => setSelected(null)}
          dialogActionButton={
            <Button
              onClick={async e => {
                e.stopPropagation();
                setSelected(null);
                await handleProductTypeChoice(selected, handleClose);
              }}
              color="primary"
              variant="contained"
              startIcon={<AddShoppingCartIcon />}
            >
              {t("Add to list", {
                ns: "TemplatesSearch",
              })}
            </Button>
          }
        />
      )}
    </Stack>
  );
};

function ProductTypesSearchModalListHeader({
  searchTerm,
  setSearchTerm,
  handleSearchValueChange,
  activeView,
  setActiveView,
}: {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearchValueChange?: (value: string) => void;
  activeView: CollectionView;
  setActiveView: (newValue: CollectionView | undefined) => void;
}) {
  const { t } = useTranslate(["Global"]);

  return (
    <>
      <ListHeader
        elementsOrder="separate-rows"
        SwitchViewButton={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        SearchField={
          <Stack direction={"row"} flex={1} spacing={1}>
            <DebouncedSearchInput
              defaultValue={searchTerm}
              placeholder={t("Search", {
                ns: "Global",
              })}
              onChangeSearchTerm={setSearchTerm}
              onImmediateChangeSearchTerm={handleSearchValueChange}
              autoFocus
              style={{ flexGrow: 1 }}
            />
          </Stack>
        }
      />
    </>
  );
}
