import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal, Switch } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import { namedOperations } from "../../../../clients/graphqlTypes";
import {
  ProductTypePopularPropertyFiltersEditModal_PimProductTypeFragment,
  useProductTypePopularPropertyFiltersEditModalQuery,
  useSetProductTypePopularPropertyFiltersMutation,
} from "./ProductTypePopularPropertyFiltersEditModal.generated";
import { ProductTypesSearchModal } from "./ProductTypesSearchModal";

export function ProductTypePopularPropertyFiltersEditModal({
  handleClose,
}: {
  handleClose: () => void;
}) {
  const { t } = useTranslate(["ProductSearch", "Global"]);

  const [selectedProductType, setSelectedProductType] = React.useState<Omit<
    ProductTypePopularPropertyFiltersEditModal_PimProductTypeFragment,
    "__typename"
  > | null>(null);

  if (!selectedProductType) {
    return (
      <ProductTypesSearchModal
        handleClose={handleClose}
        handleProductTypeChoice={productType => {
          setSelectedProductType(productType);
        }}
      />
    );
  }

  return (
    <ProductTypePopularPropertyFiltersSelectModal
      productType={selectedProductType}
      resetProductType={() => setSelectedProductType(null)}
      handleClose={handleClose}
    />
  );
}

type FormValues = { id: string; label: string; isPopular: boolean }[];

function ProductTypePopularPropertyFiltersSelectModal({
  productType,
  resetProductType,
  handleClose,
}: {
  productType: Omit<
    ProductTypePopularPropertyFiltersEditModal_PimProductTypeFragment,
    "__typename"
  >;
  resetProductType: () => void;
  handleClose: () => void;
}) {
  const { t } = useTranslate(["Product", "ProductSearch", "Global"]);

  const client = useApolloClient();
  const query = useProductTypePopularPropertyFiltersEditModalQuery({
    client,
    variables: {
      productTypeId: productType.id,
    },
  });
  const propertyTypes = getDataOrNull(
    query.data?.pimProductTypePropertyTypeFavourites
  )?.productType.propertyTypes.map(pt => pt.propertyType);
  const popularPropertyTypes = getDataOrNull(
    query.data?.pimProductTypePropertyTypeFavourites
  )?.propertyTypeFavourites;

  const [setPopularFilters] = useSetProductTypePopularPropertyFiltersMutation({
    client,
    refetchQueries: [
      namedOperations.Query.ProductTypePopularPropertyFiltersEditModal,
    ],
    awaitRefetchQueries: true,
  });

  const initialValues = React.useMemo(
    (): FormValues =>
      propertyTypes
        ?.map(propertyType => ({
          id: propertyType.id,
          label: propertyType.label,
          isPopular:
            popularPropertyTypes?.some(
              popularPropertyType => popularPropertyType.id === propertyType.id
            ) ?? false,
        }))
        .filter((property): property is FormValues[number] => !!property.id)
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [popularPropertyTypes, propertyTypes]
  );

  const handleSubmit = React.useCallback(
    async (values: FormValues) => {
      await setPopularFilters({
        variables: {
          input: {
            productTypeId: productType.id,
            propertyTypeIds: values
              .filter(property => property.isPopular)
              .map(property => property.id),
          },
        },
      });
    },
    [productType.id, setPopularFilters]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formikProps => (
        <Modal
          title={`${productType.label} (${productType.key})`}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Change product type", { ns: "ProductSearch" }),
              handleClick: () => resetProductType(),
              buttonProps: {
                disabled: formikProps.isSubmitting,
                variant: "text",
              },
            },
            {
              label: t("Close", { ns: "Global" }),
              handleClick: async () => {
                handleClose();
              },
              buttonProps: {
                disabled: formikProps.isSubmitting,
                variant: "text",
              },
            },
            {
              label: t("Save", { ns: "Global" }),
              buttonProps: {
                loading: formikProps.isSubmitting,
                form: formId,
                type: "submit",
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack alignItems={"flex-start"}>
              {formikProps.values.map(({ id, label, isPopular }, index) => (
                <Switch
                  label={label}
                  checked={isPopular}
                  onChange={() => {
                    formikProps.setFieldValue(index.toString(), {
                      id,
                      label,
                      isPopular: !isPopular,
                    });
                  }}
                />
              ))}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
