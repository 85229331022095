import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  MediaListItem,
  ModalOpenButton,
  StatusChip,
  useFormatting,
} from "@msys/ui";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  namedOperations,
  TemplateListsSortBy,
} from "../../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { ButtonCreate } from "../../../commons/button/Button";
import { SwitchCollectionViewButton } from "../../../commons/button/SwitchCollectionViewButton";
import { CollectionView } from "../../../commons/hooks/useCollectionView";
import { Page, PageTopbarItem } from "../../../commons/layout/Page";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../constants";
import { CreateTemplateListModal } from "../../../features/templateLists/CreateTemplateListModal";
import { GetTemplateListCodeModal } from "../../../features/templateLists/GetTemplateListCodeModal";
import { SelectDefaultTemplateListModal } from "../../../features/templateLists/SelectDefaultTemplateListModal";
import { useTemplateListsList } from "../../../features/templateLists/useTemplateListsList";
import { useDataGridStateStore } from "../../../features/users/useDataGridStateStore";
import {
  TemplateLists_TemplateListFragment,
  TemplateListsQueryVariables,
  useDeleteTemplateListMutation,
  useTemplateListsQuery,
} from "./TemplateLists.generated";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

interface Props {
  submenuItems: PageTopbarItem[];
  pathToDocList: string;
}

export const TemplateLists = ({ submenuItems, pathToDocList }: Props) => {
  const navigate = useNavigate();

  const { t } = useTranslate(["TemplateLists", "Templates", "Global"]);
  const { getFormattedDate } = useFormatting();

  const {
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    activeView,
    setActiveView,
    queryBaseVariables,
    toRemoveParams,
  } = useTemplateListsList<TemplateListsQueryVariables>({
    activeViewStorageKey: "template-lists",
    activeViewInitialDesktopValue: "table",
    activeViewInitialMobileValue: "list",
    initialFilters: {},
  });

  const client = useApolloClient();
  const query = useTemplateListsQuery({
    client,
    variables: {
      ...queryBaseVariables,
      sorting,
      searchTerm: filters.searchTerm,
    },
  });

  const [deleteTemplate, { loading: deleteTemplateLoading }] =
    useDeleteTemplateListMutation({
      client,
      awaitRefetchQueries: true,
      refetchQueries: [namedOperations.Query.TemplateLists],
    });

  const templateLists = getDataOrNull(
    (query.data ?? query.previousData)?.templateLists
  );
  const items = templateLists?.edges.map(e => e.node);
  const totalCount = templateLists?.totalCount ?? 0;

  const templateListsColumns = React.useMemo(
    (): GridColDef<TemplateLists_TemplateListFragment>[] => [
      {
        field: "title",
        headerName: t("Title", { ns: "TemplateLists" }),
        flex: 3,
        minWidth: 150,
        sortable: true,
        renderCell: ({ row }) => row.title,
      },
      // {
      //   field: "status",
      //   headerName: t("Status", { ns: "TemplateLists" }),
      //   width: 140,
      //   sortable: false,
      //   renderCell: ({ row }) =>
      //     row.isDefault ? (
      //       <StatusChip
      //         label={t("Default", { ns: "TemplateLists" })}
      //         color={"success"}
      //         variant={"outlined"}
      //         size="small"
      //       />
      //     ) : null,
      // },
      {
        field: "createdAt",
        headerName: t("Created", { ns: "TemplateLists" }),
        width: 120,
        sortable: true,
        renderCell: ({ row }) => getFormattedDate(row.createdAt),
      },
      // {
      //   field: "code",
      //   headerName: t("Code", { ns: "TemplateLists" }),
      //   minWidth: 160,
      //   sortable: false,
      //   renderCell: ({ row }) => (
      //     <ModalOpenButton
      //       Modal={GetTemplateListCodeModal}
      //       modalProps={{ templateListId: row.id }}
      //     >
      //       <Button
      //         size="extra-small"
      //         color="primary"
      //         variant="contained"
      //         onClick={e => {
      //           e.preventDefault();
      //           e.stopPropagation();
      //         }}
      //       >
      //         {t("Get list code", { ns: "TemplateLists" })}
      //       </Button>
      //     </ModalOpenButton>
      //   ),
      // },
      {
        field: "actions",
        headerName: "",
        filterable: false,
        sortable: false,
        width: 40,
        renderCell: ({ row }) =>
          !row.isDefault ? (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
                <ModalOpenButton
                  Modal={ConfirmModal}
                  modalProps={{
                    title: t(
                      "Are you sure you want to remove this template list?",
                      { ns: "TemplateLists" }
                    ),
                    handleConfirm: async () => {
                      await deleteTemplate({
                        variables: {
                          input: {
                            templateListId: row.id,
                          },
                        },
                      });
                    },
                  }}
                >
                  <IconButton
                    size="small"
                    color={"primary"}
                    sx={{ marginLeft: "-10px" }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </ModalOpenButton>
              </RestrictedByOrganisationPermissionWithDebug>
            </Stack>
          ) : null,
      },
    ],
    [t, getFormattedDate, deleteTemplate]
  );

  const stateStore = useDataGridStateStore("TemplateLists");

  const renderItems = (items: TemplateLists_TemplateListFragment[] = []) => {
    switch (activeView) {
      case "table":
        return (
          <DataGrid<TemplateLists_TemplateListFragment>
            stateStore={stateStore}
            loading={query.loading}
            hideFooter={totalCount === 0}
            columns={templateListsColumns}
            rows={items}
            onRowClick={({ row }) => {
              return navigate(`${pathToDocList}/${row.id}`);
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={newPaginationModel => {
              setPaginationModel(newPaginationModel);
            }}
            disableColumnFilter
            pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
            rowCount={totalCount}
            sortModel={sorting.map(s => ({
              field: s.column,
              sort: s.direction,
            }))}
            onSortModelChange={newModel => {
              setSorting(
                newModel.map(({ field, sort }) => ({
                  column: field as TemplateListsSortBy,
                  direction: sort ?? "asc",
                }))
              );
            }}
            localeText={{
              noRowsLabel: t("There are no items to display", {
                ns: "Global",
              }),
              MuiTablePagination: {
                labelRowsPerPage: t("Rows per page", {
                  ns: "Global",
                }),
              },
            }}
          />
        );
      case "list": {
        if (query.loading) {
          return <LoadingSpinner />;
        }
        if (!query.loading && (!items || items.length === 0))
          return (
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <Typography color="grey.500" variant="body2" textAlign="center">
                {t("There are no template lists", { ns: "TemplateLists" })}
              </Typography>
            </Box>
          );
        return (
          <Stack spacing={1}>
            {items.map((item, index) => {
              return (
                <MediaListItem
                  key={item.id}
                  // @ts-ignore
                  component={Link}
                  to={`${pathToDocList}/${item.id}`}
                  title={item.title}
                />
              );
            })}
            <TablePagination
              component="div"
              count={totalCount}
              page={paginationModel.page}
              onPageChange={(event, newPage) => {
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: newPage,
                });
              }}
              rowsPerPage={paginationModel.pageSize}
              onRowsPerPageChange={event => {
                setPaginationModel({
                  pageSize: parseInt(event.target.value, 10),
                  page: 0,
                });
              }}
              labelRowsPerPage={t("Per page:", { ns: "Global" })}
            />
          </Stack>
        );
      }
    }
  };

  return (
    <Page
      subtitle={t("Template lists", { ns: "Templates" })}
      submenuItems={submenuItems}
      // action={
      //   <>
      //     <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
      //       <ModalOpenButton Modal={SelectDefaultTemplateListModal}>
      //         <Button color="primary" variant="contained">
      //           {t("Select default list", { ns: "TemplateLists" })}
      //         </Button>
      //       </ModalOpenButton>
      //     </RestrictedByOrganisationPermissionWithDebug>
      //   </>
      // }
    >
      <PageContainer>
        <ListHeader
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", { ns: "Global" })}
              defaultValue={filters.searchTerm}
              onChangeSearchTerm={newValue =>
                setFilters(filters => ({
                  ...filters,
                  searchTerm: newValue,
                }))
              }
            />
          }
          CreateButton={
            <ModalOpenButton
              Modal={CreateTemplateListModal}
              modalProps={{
                handleComplete: templateListId => {
                  navigate(`${pathToDocList}/${templateListId}`);
                },
                refetchQueries: [namedOperations.Query.TemplateLists],
              }}
            >
              <ButtonCreate
                title={t("New template list", {
                  ns: "TemplateLists",
                })}
              />
            </ModalOpenButton>
          }
          mb={2}
        />
        {renderItems(items)}
      </PageContainer>
    </Page>
  );
};
