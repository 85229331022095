import { useApolloClient } from "@apollo/client";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { assertNever } from "@msys/common";
import { Modal, ModalOpenButton, RadioGroup, ellipsisStyle } from "@msys/ui";
import {
  Box,
  Button,
  DialogContentText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { omit, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { AddressInput, Salutation } from "../../../clients/graphqlTypes";
import {
  ActionLine,
  ContactLine,
  EmailLine,
  LocationLine,
  PhoneLine,
} from "../../commons/DataItem";
import { CheckboxField } from "../../commons/form-fields/CheckboxField";
import { EmailField } from "../../commons/form-fields/EmailField";
import { PhoneField } from "../../commons/form-fields/PhoneField";
import { AddressField } from "../addresses/AddressField";
import {
  CreateCrmOrganisation_CrmCompanyFragment,
  useCreateCrmOrganisationMutation,
} from "../crm-companies/CrmCompany.generated";
import { RequestSupplierModal } from "../crm-companies/modals/RequestSupplierModal";
import { useCreateCrmUserMutation } from "../crm-persons/CrmPerson.generated";
import { useEmailAddressValidationSchema } from "../email/validateEmailAddress";
import { useHasSapS4HanaIntegration } from "../sap-s4-hana/hooks";
import { SupplierSelect } from "../suppliers/SupplierSelect";
import { UserTitleWithNameField } from "../users/UserTitleWithNameField";
import {
  SapDuplicateCrmCompanyFragment,
  useCopySupplierOrganisationAsCrm__NewSupplierMutation,
  useSapCreateBusinessPartnerCraftsmanMutation,
  useSapCreateBusinessPartnerEndClientMutation,
  useSapSearchBusinessPartnerCustomerDuplicatesLazyQuery,
  useSapSearchBusinessPartnerEndClientDuplicatesLazyQuery,
} from "./CrmCreateModal.generated";
import {
  ALL_CONTACT_TYPES,
  CrmContactType,
  useCrmContactTypes,
} from "./useCrmContactTypes";
import { getAddressLabel } from "../addresses/helpers";

const DEFAULT_CONTACT_TYPE: CrmContactType = "INDIVIDUAL";

interface Props {
  id?: string;
  title?: string;
  allowedContactTypes?: CrmContactType[];
  handleClose: () => void;
  handleComplete?: (
    handleClose: () => void,
    organisation: CreateCrmOrganisation_CrmCompanyFragment,
    userId?: string
  ) => Promise<void> | void;
  refetchQueries?: string[];
  prefill?: {
    email?: string;
  };
}

export const CrmCreateModal = (props: Props) => {
  const { t } = useTranslate(["Global", "CrmOrganisations"]);

  const hasSapS4HanaIntegration = useHasSapS4HanaIntegration();

  return hasSapS4HanaIntegration ? (
    <CrmCreateModalSap4Hana {...props} />
  ) : (
    <CrmCreateModalInternal {...props} />
  );
};

type SapS4HanaClientType = "END_CLIENT" | "CRAFTSMAN";
const defaultSapS4HanaClientType: SapS4HanaClientType = "END_CLIENT";

export const CrmCreateModalSap4Hana = ({
  id,
  title,
  allowedContactTypes = ALL_CONTACT_TYPES,
  handleClose,
  handleComplete,
  refetchQueries,
  prefill,
}: Props) => {
  const { t } = useTranslate(["Global", "CrmOrganisations", "SapS4Hana"]);
  const clientTypeOptions: { value: SapS4HanaClientType; label: string }[] = [
    { value: "END_CLIENT", label: t("End client", { ns: "SapS4Hana" }) },
    { value: "CRAFTSMAN", label: t("Craftsman", { ns: "SapS4Hana" }) },
  ];

  const [clientType, setClientType] = React.useState<SapS4HanaClientType>(
    defaultSapS4HanaClientType
  );
  const formId = React.useMemo(() => uniqueId(), []);
  const [formikState, setFormikState] =
    React.useState<FormikObserveState | null>(null);

  return (
    <Modal
      id={id}
      title={title ?? t("Create client", { ns: "CrmOrganisations" })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
            disabled: formikState?.isSubmitting,
          },
        },
        {
          label: t("Create", { ns: "Global" }),
          buttonProps: {
            type: "submit",
            form: formId,
            loading: formikState?.isSubmitting || formikState?.isValidating,
            disabled: !formikState?.isValid,
          },
        },
      ]}
    >
      <Stack spacing={2} flex={1}>
        <RadioGroup<SapS4HanaClientType>
          inline
          options={clientTypeOptions}
          value={clientType}
          onChange={value => setClientType(value)}
          disabled={formikState?.isSubmitting}
        />
        {clientType === "END_CLIENT" && (
          <AddSapS4HanaEndClientForm
            formId={formId}
            handleComplete={async params => {
              switch (params.type) {
                case "SAP_CREATION": {
                  const launchpadUrl = params.fioriLaunchPadUrl;
                  if (Capacitor.isNativePlatform()) {
                    try {
                      await Browser.open({
                        url: launchpadUrl,
                        presentationStyle: "fullscreen",
                      });
                    } catch (e) {
                      const tab = window.open(launchpadUrl, "_system");
                      if (tab) tab.focus();
                    }
                  } else {
                    const tab = window.open(launchpadUrl, "_system");
                    if (tab) tab.focus();
                  }
                  handleClose();
                  break;
                }
                case "EXISTING_CRM": {
                  if (handleComplete) {
                    handleComplete(handleClose, params.crmCompany);
                  } else {
                    handleClose();
                  }

                  break;
                }
                default:
                  assertNever(params);
              }
            }}
            refetchQueries={refetchQueries}
            handleFormikObserveStateChange={setFormikState}
            prefill={prefill}
          />
        )}
        {clientType === "CRAFTSMAN" && (
          <AddSapS4HanaCraftsmanForm
            formId={formId}
            handleComplete={async params => {
              switch (params.type) {
                case "SAP_CREATION": {
                  handleClose();
                  break;
                }
                case "EXISTING_CRM": {
                  if (handleComplete) {
                    handleComplete(handleClose, params.crmCompany);
                  } else {
                    handleClose();
                  }

                  break;
                }
                default:
                  assertNever(params);
              }
            }}
            refetchQueries={refetchQueries}
            handleFormikObserveStateChange={setFormikState}
            prefill={prefill}
          />
        )}
      </Stack>
    </Modal>
  );
};

// Add SAP end client

interface AddSapS4HanaEndClientFormValues {
  salutation: Salutation;
  firstname: string;
  familyname: string;
  phone: string;
  email: string;
  address: AddressInput | null;
  hasConsentToDataProcessing: boolean;
  duplicateCheckDone: boolean;
}

function AddSapS4HanaEndClientForm({
  formId,
  refetchQueries,
  handleComplete,
  handleFormikObserveStateChange,
  prefill,
}: {
  formId: string;
  refetchQueries?: string[];
  handleComplete: (
    params:
      | {
          type: "EXISTING_CRM";
          crmCompany: CreateCrmOrganisation_CrmCompanyFragment;
        }
      | { type: "SAP_CREATION"; fioriLaunchPadUrl: string }
  ) => void;
  handleFormikObserveStateChange: (state: FormikObserveState) => void;
  prefill?: {
    email?: string;
  };
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate([
    "UserDetails",
    "OrganisationProfile",
    "Global",
    "SapS4Hana",
    "CrmOrganisations",
  ]);

  const { createEmailAddressValidationSchema } =
    useEmailAddressValidationSchema();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape(
        {
          salutation: Yup.string()
            .label(t("Salutation", { ns: "Global" }))
            .required(),
          firstname: Yup.string()
            .label(t("First name", { ns: "UserDetails" }))
            .required()
            .min(1),
          familyname: Yup.string()
            .label(t("Last name", { ns: "UserDetails" }))
            .required()
            .min(1),
          phone: Yup.string()
            .label(t("Phone", { ns: "UserDetails" }))
            .required(),
          email: createEmailAddressValidationSchema(
            t("Email", { ns: "UserDetails" })
          ).required(),
          address: Yup.object()
            .shape({
              streetLine1: Yup.string(),
              postalCode: Yup.string(),
              city: Yup.string(),
            })
            .label(t("Address", { ns: "CrmOrganisations" }))
            .nullable()
            .required(t("Address is mandatory", { ns: "Global" })),
          hasConsentToDataProcessing: Yup.boolean()
            .label(
              t("End client gave consent to data processing", {
                ns: "SapS4Hana",
              })
            )
            .isTrue(
              t("Client must gave consent to data processing", {
                ns: "SapS4Hana",
              })
            )
            .required(),
          duplicateCheckDone: Yup.boolean().isTrue().required(),
        },
        [
          ["salutation", "firstname"],
          ["salutation", "familyname"],
          ["firstname", "familyname"],
        ]
      ),
    [createEmailAddressValidationSchema, t]
  );

  const initialValues = React.useMemo(
    (): AddSapS4HanaEndClientFormValues => ({
      salutation: "mr",
      firstname: "",
      familyname: "",
      phone: "",
      email: prefill?.email ?? "",
      address: null,
      hasConsentToDataProcessing: false,
      duplicateCheckDone: false,
    }),
    [prefill?.email]
  );

  const client = useApolloClient();
  const [sapCreateBusinessPartnerEndClient] =
    useSapCreateBusinessPartnerEndClientMutation({
      client,
      refetchQueries,
      awaitRefetchQueries: true,
    });
  const [
    sapSearchBusinessPartnerEndClientDuplicates,
    {
      data: sapSearchBusinessPartnerEndClientDuplicatesData,
      loading: sapSearchBusinessPartnerEndClientDuplicatesLoading,
    },
  ] = useSapSearchBusinessPartnerEndClientDuplicatesLazyQuery({
    client,
  });
  const onSubmit = async (values: AddSapS4HanaEndClientFormValues) => {
    if (!values.address) {
      return;
    }

    try {
      const r = await sapCreateBusinessPartnerEndClient({
        variables: {
          input: {
            salutation: values.salutation,
            firstname: values.firstname,
            familyname: values.familyname,
            phone: values.phone,
            email: values.email,
            address: {
              ...omit(values.address, "__typename"),
              location: values.address.location
                ? omit(values.address.location, "__typename")
                : null,
            },
          },
        },
      });
      enqueueSnackbar(t("Client created", { ns: "UserDetails" }));
      if (r.data?.sapCreateBusinessPartnerEndClient.fioriLaunchPadUrl) {
        handleComplete({
          type: "SAP_CREATION",
          fioriLaunchPadUrl:
            r.data.sapCreateBusinessPartnerEndClient.fioriLaunchPadUrl,
        });
      } else {
        throw new Error(`No url provided in response`);
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Formik<AddSapS4HanaEndClientFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formikProps => (
        <Form id={formId}>
          <Stack spacing={1}>
            <Box>
              <UserTitleWithNameField
                titleField="salutation"
                firstnameField="firstname"
                familynameField="familyname"
                isRequiredOption="BOTH"
              />
            </Box>
            <PhoneField
              label={t("Phone", { ns: "UserDetails" })}
              name="phone"
              required
            />
            <EmailField
              label={t("Email", { ns: "UserDetails" })}
              name="email"
              required
            />
            <AddressField
              label={t("Address", { ns: "CrmOrganisations" })}
              name="address"
              required
            />
            <CheckboxField
              name={"hasConsentToDataProcessing"}
              label={t("End client gave consent to data processing", {
                ns: "SapS4Hana",
              })}
            />
            <Button
              size="small"
              color="secondary"
              variant={"contained"}
              style={{ maxWidth: "200px" }}
              disabled={
                !(
                  formikProps.values.firstname &&
                  formikProps.values.familyname &&
                  formikProps.values.address
                ) || sapSearchBusinessPartnerEndClientDuplicatesLoading
              }
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();

                await sapSearchBusinessPartnerEndClientDuplicates({
                  variables: {
                    input: {
                      firstname: formikProps.values.firstname,
                      familyname: formikProps.values.familyname,
                      address: formikProps.values.address,
                    },
                  },
                  onCompleted: () => {
                    formikProps.setFieldValue("duplicateCheckDone", true, true);
                  },
                });
              }}
            >
              {t("Check for duplicates", {
                ns: "SapS4Hana",
              })}
            </Button>
            {sapSearchBusinessPartnerEndClientDuplicatesData &&
              (sapSearchBusinessPartnerEndClientDuplicatesData
                .sapSearchBusinessPartnerEndClientDuplicates.scoredCrmCompanies
                .length > 0 ? (
                <Stack>
                  {sapSearchBusinessPartnerEndClientDuplicatesData.sapSearchBusinessPartnerEndClientDuplicates.scoredCrmCompanies
                    .slice(0, 5)
                    .map(({ crmCompany }) => (
                      <SapCrmCompanyDuplicateCheckListItem
                        key={crmCompany.id}
                        crmCompany={crmCompany}
                        Action={
                          <Button
                            size="medium"
                            color="primary"
                            variant={"contained"}
                            onClick={async e => {
                              e.preventDefault();
                              e.stopPropagation();

                              handleComplete({
                                type: "EXISTING_CRM",
                                crmCompany,
                              });
                            }}
                          >
                            {t("Pick", {
                              ns: "SapS4Hana",
                            })}
                          </Button>
                        }
                      />
                    ))}
                </Stack>
              ) : (
                <Typography>
                  {t("No duplicates found", {
                    ns: "SapS4Hana",
                  })}
                </Typography>
              ))}
          </Stack>
          <FormikStateObserver callback={handleFormikObserveStateChange} />
        </Form>
      )}
    </Formik>
  );
}

interface AddSapS4HanaCraftsmanFormValues {
  salutation: Salutation;
  firstname: string;
  familyname: string;
  companyName: string;
  phone: string;
  email: string;
  address: AddressInput | null;
  isFastCreation: boolean;
  duplicateCheckDone: boolean;
}

function AddSapS4HanaCraftsmanForm({
  formId,
  refetchQueries,
  handleComplete,
  handleFormikObserveStateChange,
  prefill,
}: {
  formId: string;
  refetchQueries?: string[];
  handleComplete: (
    params:
      | {
          type: "EXISTING_CRM";
          crmCompany: CreateCrmOrganisation_CrmCompanyFragment;
        }
      | { type: "SAP_CREATION" }
  ) => void;
  handleFormikObserveStateChange: (state: FormikObserveState) => void;
  prefill?: {
    email?: string;
  };
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate([
    "UserDetails",
    "OrganisationProfile",
    "Global",
    "SapS4Hana",
    "CrmOrganisations",
  ]);

  const { createEmailAddressValidationSchema } =
    useEmailAddressValidationSchema();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape(
        {
          salutation: Yup.string()
            .label(t("Salutation", { ns: "Global" }))
            .required(),
          firstname: Yup.string()
            .label(t("First name", { ns: "UserDetails" }))
            .required()
            .min(1),
          familyname: Yup.string()
            .label(t("Last name", { ns: "UserDetails" }))
            .required()
            .min(1),
          companyName: Yup.string()
            .label(t("Company Name", { ns: "UserDetails" }))
            .required()
            .min(1),
          phone: Yup.string()
            .label(t("Phone", { ns: "UserDetails" }))
            .required(),
          email: createEmailAddressValidationSchema(
            t("Email", { ns: "UserDetails" })
          ).required(),
          address: Yup.object()
            .shape({
              streetLine1: Yup.string(),
              postalCode: Yup.string(),
              city: Yup.string(),
            })
            .label(t("Address", { ns: "CrmOrganisations" }))
            .nullable()
            .required(t("Address is mandatory", { ns: "Global" })),
          duplicateCheckDone: Yup.boolean().isTrue().required(),
        },
        [
          ["salutation", "firstname"],
          ["salutation", "familyname"],
          ["firstname", "familyname"],
        ]
      ),
    [createEmailAddressValidationSchema, t]
  );

  const initialValues = React.useMemo(
    (): AddSapS4HanaCraftsmanFormValues => ({
      salutation: "mr",
      firstname: "",
      familyname: "",
      phone: "",
      email: prefill?.email ?? "",
      address: null,
      companyName: "",
      isFastCreation: false,
      duplicateCheckDone: false,
    }),
    [prefill?.email]
  );

  const client = useApolloClient();
  const [sapCreateBusinessPartnerCustomer] =
    useSapCreateBusinessPartnerCraftsmanMutation({
      client,
      refetchQueries,
      awaitRefetchQueries: true,
    });
  const [
    sapSearchBusinessPartnerCustomerDuplicates,
    {
      data: sapSearchBusinessPartnerCustomerDuplicatesData,
      loading: sapSearchBusinessPartnerCustomerDuplicatesLoading,
    },
  ] = useSapSearchBusinessPartnerCustomerDuplicatesLazyQuery({
    client,
  });
  const onSubmit = async (values: AddSapS4HanaCraftsmanFormValues) => {
    if (!values.address) {
      return;
    }

    try {
      await sapCreateBusinessPartnerCustomer({
        variables: {
          input: {
            salutation: values.salutation,
            firstname: values.firstname,
            familyname: values.familyname,
            phone: values.phone,
            email: values.email,
            address: {
              ...omit(values.address, "__typename"),
              location: values.address.location
                ? omit(values.address.location, "__typename")
                : null,
            },
            companyName: values.companyName,
            isFastCreation: values.isFastCreation,
          },
        },
      });
      enqueueSnackbar(t("Craftsman created", { ns: "UserDetails" }));
      handleComplete({ type: "SAP_CREATION" });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Formik<AddSapS4HanaCraftsmanFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formikProps => (
        <Form id={formId}>
          <Stack spacing={1}>
            <Field
              component={TextField}
              name="companyName"
              label={t("Company Name", {
                ns: "UserDetails",
              })}
              required
            />
            <Box>
              <UserTitleWithNameField
                titleField="salutation"
                firstnameField="firstname"
                familynameField="familyname"
                isRequiredOption="BOTH"
              />
            </Box>
            <PhoneField
              label={t("Phone", { ns: "UserDetails" })}
              name="phone"
              required
            />
            <EmailField
              label={t("Email", { ns: "UserDetails" })}
              name="email"
              required
            />
            <AddressField
              label={t("Address", { ns: "CrmOrganisations" })}
              name="address"
              required
            />
            <CheckboxField
              name={"isFastCreation"}
              label={t("Do fast creation", {
                ns: "SapS4Hana",
              })}
            />
            <Button
              size="small"
              color="secondary"
              variant={"contained"}
              style={{ maxWidth: "200px" }}
              disabled={
                !(
                  formikProps.values.companyName &&
                  formikProps.values.firstname &&
                  formikProps.values.familyname &&
                  formikProps.values.address
                ) || sapSearchBusinessPartnerCustomerDuplicatesLoading
              }
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();

                await sapSearchBusinessPartnerCustomerDuplicates({
                  variables: {
                    input: {
                      firstname: formikProps.values.firstname,
                      familyname: formikProps.values.familyname,
                      address: formikProps.values.address,
                      companyName: formikProps.values.companyName,
                    },
                  },
                  onCompleted: () => {
                    formikProps.setFieldValue("duplicateCheckDone", true, true);
                  },
                });
              }}
            >
              {t("Check for duplicates", {
                ns: "SapS4Hana",
              })}
            </Button>
            {sapSearchBusinessPartnerCustomerDuplicatesData &&
              (sapSearchBusinessPartnerCustomerDuplicatesData
                .sapSearchBusinessPartnerCustomerDuplicates.scoredCrmCompanies
                .length > 0 ? (
                <Stack>
                  {sapSearchBusinessPartnerCustomerDuplicatesData.sapSearchBusinessPartnerCustomerDuplicates.scoredCrmCompanies
                    .slice(0, 5)
                    .map(({ crmCompany }) => (
                      <SapCrmCompanyDuplicateCheckListItem
                        key={crmCompany.id}
                        crmCompany={crmCompany}
                        Action={
                          <Button
                            size="small"
                            color="primary"
                            variant={"contained"}
                            onClick={async e => {
                              e.preventDefault();
                              e.stopPropagation();

                              handleComplete({
                                type: "EXISTING_CRM",
                                crmCompany,
                              });
                            }}
                          >
                            {t("Pick", {
                              ns: "SapS4Hana",
                            })}
                          </Button>
                        }
                      />
                    ))}
                </Stack>
              ) : (
                <Typography>
                  {t("No duplicates found", {
                    ns: "SapS4Hana",
                  })}
                </Typography>
              ))}
          </Stack>
          <FormikStateObserver callback={handleFormikObserveStateChange} />
        </Form>
      )}
    </Formik>
  );
}

export const CrmCreateModalInternal = ({
  id,
  title,
  allowedContactTypes = ALL_CONTACT_TYPES,
  handleClose,
  handleComplete,
  refetchQueries,
  prefill,
}: Props) => {
  const { t } = useTranslate(["Global", "CrmOrganisations"]);
  const { contactTypeOptions } = useCrmContactTypes();

  const [crmContactType, setCrmContactType] = React.useState<CrmContactType>(
    allowedContactTypes.includes(DEFAULT_CONTACT_TYPE)
      ? DEFAULT_CONTACT_TYPE
      : allowedContactTypes[0]
  );
  const formId = React.useMemo(() => uniqueId(), []);
  const [formikState, setFormikState] =
    React.useState<FormikObserveState | null>(null);

  const onComplete = React.useCallback(
    async (
      organisation: CreateCrmOrganisation_CrmCompanyFragment,
      userId?: string
    ) => {
      if (handleComplete) {
        await handleComplete(handleClose, organisation, userId);
      } else {
        handleClose();
      }
    },
    [handleClose, handleComplete]
  );

  return (
    <Modal
      id={id}
      title={title ?? t("Create client", { ns: "CrmOrganisations" })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text", disabled: formikState?.isSubmitting },
        },
        {
          label: t("Create", {
            ns: "Global",
          }),
          buttonProps: {
            type: "submit",
            form: formId,
            loading: formikState?.isSubmitting || formikState?.isValidating,
          },
        },
      ]}
    >
      <Stack spacing={2}>
        <RadioGroup<CrmContactType>
          inline
          options={contactTypeOptions.filter(({ value }) =>
            allowedContactTypes.includes(value)
          )}
          value={crmContactType}
          onChange={value => setCrmContactType(value)}
        />
        {crmContactType === "COMPANY" && (
          <AddCrmCompanyForm
            formId={formId}
            handleComplete={onComplete}
            refetchQueries={refetchQueries}
            handleFormikObserveStateChange={setFormikState}
            prefill={prefill}
          />
        )}
        {crmContactType === "INDIVIDUAL" && (
          <AddCrmIndividualForm
            formId={formId}
            handleComplete={onComplete}
            refetchQueries={refetchQueries}
            handleFormikObserveStateChange={setFormikState}
            prefill={prefill}
          />
        )}
        {crmContactType === "SUPPLIER" && (
          <AddCrmSupplierForm
            formId={formId}
            refetchQueries={refetchQueries}
            handleComplete={onComplete}
            handleClose={handleClose}
            handleFormikObserveStateChange={setFormikState}
          />
        )}
      </Stack>
    </Modal>
  );
};

type FormikObserveState = {
  isSubmitting: boolean;
  dirty: boolean;
  isValid: boolean;
  isValidating: boolean;
};

function FormikStateObserver({
  callback,
}: {
  callback: (state: FormikObserveState) => void;
}) {
  const { isSubmitting, dirty, isValid, isValidating } = useFormikContext();

  React.useEffect(() => {
    callback({ isSubmitting, dirty, isValid, isValidating });
  }, [callback, isSubmitting, dirty, isValid, isValidating]);

  return null;
}

// ADD COMPANY

interface AddCompanyFormValues {
  companyName: string;
  companyAddress: AddressInput | null;
  contactPersonTitle: Salutation;
  contactPersonFirstname: string;
  contactPersonFamilyname: string;
  contactPersonPhone: string;
  contactPersonEmail: string;
  personHasDifferentAddress: boolean;
  contactPersonAddress: AddressInput | null;
}

function AddCrmCompanyForm({
  formId,
  refetchQueries,
  handleComplete,
  handleFormikObserveStateChange,
  prefill,
}: {
  formId: string;
  refetchQueries?: string[];
  handleComplete: (
    organisation: CreateCrmOrganisation_CrmCompanyFragment,
    userId?: string
  ) => void;
  handleFormikObserveStateChange: (state: FormikObserveState) => void;
  prefill?: {
    email?: string;
  };
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate([
    "UserDetails",
    "OrganisationProfile",
    "CrmOrganisations",
    "Global",
  ]);

  const { createEmailAddressValidationSchema } =
    useEmailAddressValidationSchema();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape(
        {
          companyName: Yup.string()
            .label(
              t("Company Name", {
                ns: "UserDetails",
              })
            )
            .required()
            .min(1),
          companyAddress: Yup.object()
            .shape({
              streetLine1: Yup.string(),
              postalCode: Yup.string(),
              city: Yup.string(),
            })
            .label(t("Address", { ns: "CrmOrganisations" }))
            .nullable(),
          contactPersonTitle: Yup.string()
            .label(t("Salutation", { ns: "Global" }))
            .required(),
          contactPersonFirstname: Yup.string()
            .label(t("First name", { ns: "UserDetails" }))
            .when("contactPersonFamilyname", {
              is: (contactPersonFamilyname: string) =>
                !!contactPersonFamilyname,
              then: schema => schema.required().min(1),
            }),
          contactPersonFamilyname: Yup.string()
            .label(t("Last name", { ns: "UserDetails" }))
            .when("contactPersonFirstname", {
              is: (contactPersonFirstname: string) => !!contactPersonFirstname,
              then: schema => schema.required().min(1),
            }),
          contactPersonPhone: Yup.string().label(
            t("Phone", { ns: "UserDetails" })
          ),
          contactPersonEmail: createEmailAddressValidationSchema(
            t("Email", { ns: "UserDetails" })
          ),
          contactPersonAddress: Yup.object()
            .shape({
              streetLine1: Yup.string(),
              postalCode: Yup.string(),
              city: Yup.string(),
            })
            .label(t("Address", { ns: "CrmOrganisations" }))
            .nullable(),
        },
        [
          ["contactPersonTitle", "contactPersonFirstname"],
          ["contactPersonTitle", "contactPersonFamilyname"],
          ["contactPersonFirstname", "contactPersonFamilyname"],
        ]
      ),
    [createEmailAddressValidationSchema, t]
  );

  const initialValues = React.useMemo(
    (): AddCompanyFormValues => ({
      companyName: "",
      companyAddress: null,
      contactPersonTitle: "mr",
      contactPersonFirstname: "",
      contactPersonFamilyname: "",
      contactPersonPhone: "",
      contactPersonEmail: prefill?.email ?? "",
      personHasDifferentAddress: false,
      contactPersonAddress: null,
    }),
    [prefill?.email]
  );

  const { createCrmOrganisationAndUser } =
    useCreateCrmOrganisationAndUser(refetchQueries);

  const onSubmit = async ({
    companyName,
    companyAddress,
    contactPersonTitle,
    contactPersonFirstname,
    contactPersonFamilyname,
    contactPersonPhone,
    contactPersonEmail,
    personHasDifferentAddress,
    contactPersonAddress,
  }: AddCompanyFormValues) => {
    const { organisation, userId, contactType } =
      await createCrmOrganisationAndUser({
        companyName,
        companyPhone: contactPersonPhone, // FIXME
        companyEmail: contactPersonEmail, // FIXME
        companyAddress,
        contactPersonTitle,
        contactPersonFirstname,
        contactPersonFamilyname,
        contactPersonPhone,
        contactPersonEmail,
        contactPersonAddress: personHasDifferentAddress
          ? contactPersonAddress
          : companyAddress,
        contactType: "COMPANY",
      });

    await handleComplete(organisation, userId);
    enqueueSnackbar(
      t("Client created", {
        ns: "UserDetails",
      })
    );
  };

  return (
    <Formik<AddCompanyFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formikProps => (
        <Form id={formId}>
          <Stack spacing={1}>
            <Field
              component={TextField}
              name="companyName"
              label={t("Company Name", {
                ns: "UserDetails",
              })}
              required
            />
            <AddressField
              label={t("Address", {
                ns: "CrmOrganisations",
              })}
              name="companyAddress"
            />

            <DialogContentText>
              {t("Contact person", {
                ns: "OrganisationProfile",
              })}
            </DialogContentText>
            <Box>
              <UserTitleWithNameField
                titleField="contactPersonTitle"
                firstnameField="contactPersonFirstname"
                familynameField="contactPersonFamilyname"
                isRequiredOption="BOTH_OR_NONE"
              />
            </Box>
            <PhoneField
              label={t("Phone", { ns: "UserDetails" })}
              name="contactPersonPhone"
            />
            <EmailField
              label={t("Email", {
                ns: "UserDetails",
              })}
              name="contactPersonEmail"
            />
            {/*<CheckboxField
              name={"personHasDifferentAddress"}
              label={t("Different address", { ns: "UserDetails" })}
            />
            {formikProps.values.personHasDifferentAddress && (
              <AddressField
                label={t("Address", {
                  ns: "CrmOrganisations",
                })}
                name="contactPersonAddress"
              />
            )}*/}
          </Stack>
          <FormikStateObserver callback={handleFormikObserveStateChange} />
        </Form>
      )}
    </Formik>
  );
}

// ADD INDIVIDUAL

interface AddIndividualFormValues {
  contactPersonTitle: Salutation;
  contactPersonFirstname: string;
  contactPersonFamilyname: string;
  phone: string;
  email: string;
  address: AddressInput | null;
}

function AddCrmIndividualForm({
  formId,
  refetchQueries,
  handleComplete,
  handleFormikObserveStateChange,
  prefill,
}: {
  formId: string;
  refetchQueries?: string[];
  handleComplete: (
    organisation: CreateCrmOrganisation_CrmCompanyFragment,
    userId: string
  ) => void;
  handleFormikObserveStateChange: (state: FormikObserveState) => void;
  prefill?: {
    email?: string;
  };
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate(["UserDetails", "Global", "CrmOrganisations"]);

  const { createEmailAddressValidationSchema } =
    useEmailAddressValidationSchema();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        contactPersonTitle: Yup.string()
          .label(
            t("Salutation", {
              ns: "Global",
            })
          )
          .required(),
        contactPersonFirstname: Yup.string()
          .label(
            t("First name", {
              ns: "UserDetails",
            })
          )
          .required()
          .min(1),
        contactPersonFamilyname: Yup.string()
          .label(
            t("Last name", {
              ns: "UserDetails",
            })
          )
          .required()
          .min(1),
        phone: Yup.string().label(
          t("Phone", {
            ns: "UserDetails",
          })
        ),
        email: createEmailAddressValidationSchema(
          t("Email", {
            ns: "UserDetails",
          })
        ),
        address: Yup.object()
          .shape({
            streetLine1: Yup.string(),
            postalCode: Yup.string(),
            city: Yup.string(),
          })
          .label(t("Address", { ns: "CrmOrganisations" }))
          .nullable(),
      }),
    [createEmailAddressValidationSchema, t]
  );

  const initialValues = React.useMemo(
    (): AddIndividualFormValues => ({
      contactPersonTitle: "mr",
      contactPersonFirstname: "",
      contactPersonFamilyname: "",
      phone: "",
      email: prefill?.email ?? "",
      address: null,
    }),
    [prefill?.email]
  );

  const { createCrmOrganisationAndUser } =
    useCreateCrmOrganisationAndUser(refetchQueries);

  const onSubmit = async ({
    contactPersonTitle,
    contactPersonFirstname,
    contactPersonFamilyname,
    phone,
    email,
    address,
  }: AddIndividualFormValues) => {
    const { organisation, userId } = await createCrmOrganisationAndUser({
      companyName: `${contactPersonFirstname.trim()} ${contactPersonFamilyname.trim()}`,
      companyPhone: phone,
      companyEmail: email,
      companyAddress: address,
      contactPersonTitle,
      contactPersonFirstname,
      contactPersonFamilyname,
      contactPersonPhone: phone,
      contactPersonEmail: email,
      contactPersonAddress: address,
      contactType: "INDIVIDUAL",
    });

    if (!userId) throw new Error("CRM user was not created");

    await handleComplete(organisation, userId);
    enqueueSnackbar(
      t("Client created", {
        ns: "UserDetails",
      })
    );
  };

  return (
    <Formik<AddIndividualFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Form id={formId}>
        <Stack spacing={1}>
          <Box>
            <UserTitleWithNameField
              titleField="contactPersonTitle"
              firstnameField="contactPersonFirstname"
              isRequiredOption="BOTH"
              familynameField="contactPersonFamilyname"
            />
          </Box>
          <PhoneField label={t("Phone", { ns: "UserDetails" })} name="phone" />
          <EmailField label={t("Email", { ns: "UserDetails" })} name="email" />
          <AddressField
            label={t("Address", { ns: "CrmOrganisations" })}
            name="address"
          />
        </Stack>
        <FormikStateObserver callback={handleFormikObserveStateChange} />
      </Form>
    </Formik>
  );
}

function useCreateCrmOrganisationAndUser(refetchQueries?: string[]) {
  const client = useApolloClient();
  const [createCrmOrganisation] = useCreateCrmOrganisationMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const [createCrmUser] = useCreateCrmUserMutation({ client });

  const createCrmOrganisationAndUser = async ({
    companyName,
    companyPhone,
    companyEmail,
    companyAddress,
    contactPersonTitle,
    contactPersonFirstname,
    contactPersonFamilyname,
    contactPersonPhone,
    contactPersonEmail,
    contactPersonAddress,
    contactType,
  }:
    | {
        companyName: string;
        companyPhone?: string | undefined;
        companyEmail?: string | undefined;
        companyAddress?: AddressInput | null;
        contactPersonTitle?: Salutation;
        contactPersonFirstname?: string | undefined;
        contactPersonFamilyname?: string | undefined;
        contactPersonPhone?: string | undefined;
        contactPersonEmail?: string | undefined;
        contactPersonAddress?: AddressInput | null;
        contactType: "COMPANY";
      }
    | {
        companyName: string;
        companyPhone?: string | undefined;
        companyEmail?: string | undefined;
        companyAddress?: AddressInput | null;
        contactPersonTitle: Salutation;
        contactPersonFirstname: string;
        contactPersonFamilyname: string;
        contactPersonPhone?: string | undefined;
        contactPersonEmail?: string | undefined;
        contactPersonAddress?: AddressInput | null;
        contactType: "INDIVIDUAL";
      }) => {
    const createOrganisationResponse = await createCrmOrganisation({
      variables: {
        input: [
          {
            title: companyName,
            phones: companyPhone
              ? [
                  {
                    type: "WORK",
                    number: companyPhone,
                    main: true,
                  },
                ]
              : undefined,
            email: companyEmail,
            billingAddress: companyAddress,
            contactType,
          },
        ],
      },
    });

    if (!createOrganisationResponse.data?.createCrmOrganisation)
      throw new Error("Failed to create CRM company");

    const organisation =
      createOrganisationResponse.data.createCrmOrganisation.crmOrganisations[0];

    if (
      !(contactPersonTitle && contactPersonFirstname && contactPersonFamilyname)
    ) {
      if (contactType === "COMPANY") {
        return {
          organisation,
          contactType,
        };
      } else if (contactType === "INDIVIDUAL") {
        throw new Error("Contact information missing");
      } else {
        assertNever(contactType);
      }
    }

    const createUserResponse = await createCrmUser({
      variables: {
        input: [
          {
            organisationId: organisation.id,
            title: contactPersonTitle,
            firstname: contactPersonFirstname,
            familyname: contactPersonFamilyname,
            phones: contactPersonPhone
              ? [
                  {
                    type: "WORK",
                    number: contactPersonPhone,
                    main: true,
                  },
                ]
              : undefined,
            email: contactPersonEmail ?? "",
            address: contactPersonAddress,
          },
        ],
      },
    });

    if (!createUserResponse.data?.createCrmUser)
      throw new Error("Failed to create CRM person");

    return {
      organisation,
      userId: createUserResponse.data.createCrmUser.users[0].id,
      contactType,
    };
  };

  return { createCrmOrganisationAndUser };
}

// ADD SUPPLIER

interface AddSupplierFormValues {
  systemSupplierOrganisation: { id: string; title: string } | null;
}

function AddCrmSupplierForm({
  formId,
  refetchQueries,
  handleComplete,
  handleClose,
  handleFormikObserveStateChange,
}: {
  formId: string;
  refetchQueries?: string[];
  handleComplete: (
    supplierOrganisation: CreateCrmOrganisation_CrmCompanyFragment
  ) => Promise<void> | void;
  handleClose: () => void;
  handleFormikObserveStateChange: (state: FormikObserveState) => void;
}) {
  const { t } = useTranslate([
    "SupplierNew",
    "Global",
    "Product",
    "OrganisationInvite",
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [copySupplierOrganisationAsCrm] =
    useCopySupplierOrganisationAsCrm__NewSupplierMutation({
      client,
      refetchQueries,
    });

  const handleCreateCrmSupplier = React.useCallback(
    async (systemSupplierOrganisationId: string) => {
      const result = await copySupplierOrganisationAsCrm({
        variables: {
          input: {
            systemOrganisationId: systemSupplierOrganisationId,
          },
        },
      });

      const crmSupplierOrg =
        result.data?.copySupplierOrganisationAsCrm.crmOrganisation;
      if (!crmSupplierOrg)
        throw new Error("Failed to copy supplier organisation as CRM");

      await handleComplete(crmSupplierOrg);
      enqueueSnackbar(
        t("Supplier added to organisation", {
          ns: "SupplierNew",
        })
      );
    },
    [copySupplierOrganisationAsCrm, enqueueSnackbar, handleComplete, t]
  );

  const onSubmit = React.useCallback(
    async (values: AddSupplierFormValues) => {
      if (!values.systemSupplierOrganisation)
        throw new Error("Supplier not selected");

      await handleCreateCrmSupplier(values.systemSupplierOrganisation.id);
    },
    [handleCreateCrmSupplier]
  );

  const initialValues: AddSupplierFormValues = {
    systemSupplierOrganisation: null,
  };
  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        systemSupplierOrganisation: Yup.object()
          .shape({ id: Yup.string().required() })
          .label(t("Supplier", { ns: "Product" }))
          .nullable()
          .required(),
      }),
    [t]
  );

  return (
    <Formik<AddSupplierFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form id={formId}>
          <Stack spacing={1}>
            <SupplierSelect
              label={t("Select an existing supplier", { ns: "SupplierNew" })}
              excludeIfAlreadyCrm={true}
              required={true}
              disabled={isSubmitting}
              productSupplierId={values.systemSupplierOrganisation?.id ?? null}
              onChange={productSupplier => {
                setFieldValue("systemSupplierOrganisation", productSupplier);
              }}
            />
            <Box paddingTop={1}>
              <DialogContentText textAlign={"center"} sx={{ marginBottom: 0 }}>
                <Box>
                  {t("Not finding your supplier?", {
                    ns: "OrganisationInvite",
                  })}
                </Box>
                <ModalOpenButton
                  Modal={RequestSupplierModal}
                  modalProps={{
                    title: t("Request creation of supplier", {
                      ns: "SupplierNew",
                    }),
                  }}
                >
                  <Button color="secondary" size="small">
                    {t("Request a supplier", {
                      ns: "OrganisationInvite",
                    })}
                  </Button>
                </ModalOpenButton>
              </DialogContentText>
            </Box>
          </Stack>
          <FormikStateObserver callback={handleFormikObserveStateChange} />
        </Form>
      )}
    </Formik>
  );
}

function SapCrmCompanyDuplicateCheckListItem({
  crmCompany,
  Action,
}: {
  crmCompany: SapDuplicateCrmCompanyFragment;
  Action?: React.ReactElement;
}) {
  const { t } = useTranslate("DataItem");
  const contact = crmCompany.contactPerson?.fullname;

  const location = crmCompany.billingAddress
    ? getAddressLabel(crmCompany.billingAddress)
    : undefined;

  const phones =
    crmCompany.phones.length > 0
      ? crmCompany.phones.map(p => p.number).join(", ")
      : undefined;

  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>{`${crmCompany.number} - ${crmCompany.title}`}</span>
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>
      {location ? (
        <Box
          gap={0.5}
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          <Tooltip title={t("Location")} arrow placement="bottom">
            <div style={{ ...ellipsisStyle, alignSelf: "flex-start" }}>
              <LocationLine>{location}</LocationLine>
            </div>
          </Tooltip>
        </Box>
      ) : null}
      {crmCompany.email ? (
        <Box
          gap={0.5}
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          <Tooltip title={t("Email")} arrow placement="bottom">
            <div style={{ ...ellipsisStyle, alignSelf: "flex-start" }}>
              <EmailLine>{crmCompany.email}</EmailLine>
            </div>
          </Tooltip>
        </Box>
      ) : null}
      {phones ? (
        <Box
          gap={0.5}
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          <Tooltip title={t("Email")} arrow placement="bottom">
            <div style={{ ...ellipsisStyle, alignSelf: "flex-start" }}>
              <PhoneLine>{phones}</PhoneLine>
            </div>
          </Tooltip>
        </Box>
      ) : null}
      {contact ? (
        <Box
          gap={0.5}
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          <Tooltip title={t("Contact")} arrow placement="bottom">
            <div style={{ ...ellipsisStyle, alignSelf: "flex-start" }}>
              <ContactLine>{contact}</ContactLine>
            </div>
          </Tooltip>
        </Box>
      ) : null}
    </Stack>
  );
}
