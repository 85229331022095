import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CollapseSection, Modal } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, Form, Formik } from "formik";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import {
  ItemType,
  ModifyItemRecommendedTemplateValuesInput,
  Props2,
} from "../../../clients/graphqlTypes";
import { SelectField } from "../../commons/form-fields/SelectField";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import {
  ExpressionInputWithVariables,
  ExpressionItemSource,
  VariableInputState,
} from "../doc-items/modals/ExpressionModal";
import { useExpressionValidation } from "../doc-items/modals/useExpressionValidation";
import {
  getEnhancedPropertyLabel,
  getPropertyType,
  isArrayType,
  isBooleanType,
  isNonComputedProp,
  isNotArrayType,
  isNumberType,
  isTextType,
} from "../doc-items/properties";
import {
  EditRecommendedTemplateModal_ItemRecommendationConfigFragment,
  EditRecommendedTemplateModal_ItemRecommendationFragment,
  EditRecommendedTemplateModal_RecommendedTemplateConfigFragment,
  useEditRecommendedTemplateModal__LatestAvailableVersionQuery,
  useEditRecommendedTemplateModalQuery,
} from "./EditRecommendedTemplateModal.generated";

const EXPRESSION_SOURCES: ExpressionItemSource[] = ["self"];

interface FormValues {
  bindProps: {
    destKey: string;
    sourceKey: string;
  }[];
  includeIfExpr: VariableInputState;
  eligibleIfExpr: VariableInputState;
}

interface Props {
  docId: string;
  itemId: string;
  recommendation: EditRecommendedTemplateModal_ItemRecommendationFragment;
  recommendationConfig: EditRecommendedTemplateModal_ItemRecommendationConfigFragment;
  recommendedTemplateConfig: EditRecommendedTemplateModal_RecommendedTemplateConfigFragment;
  allowedItemTypes: ItemType[];
  handleClose(): void;
  handleComplete(
    values: ModifyItemRecommendedTemplateValuesInput | null
  ): Promise<void> | void;
}

export const EditRecommendedTemplateModal = ({
  docId,
  itemId,
  recommendation,
  recommendationConfig,
  recommendedTemplateConfig,
  allowedItemTypes,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const query = useEditRecommendedTemplateModalQuery({
    client,
    variables: {
      itemId,
      docId,
      templateId: recommendedTemplateConfig.templateId,
      templateVersionNumber: recommendedTemplateConfig.templateVersionNumber,
    },
  });
  const queryLatestAvailableVersion =
    useEditRecommendedTemplateModal__LatestAvailableVersionQuery({
      client,
      variables: {
        templateId: recommendedTemplateConfig.templateId,
      },
      fetchPolicy: "no-cache",
    });

  const templateLatestAvailableVersion = getDataOrNull(
    queryLatestAvailableVersion.data?.quoteTemplateLatestAvailableVersion
  )?.quoteTemplate?.resolvedAsReadModelVersionNumber;

  const templateLatestAvailableVersionRootProps = (
    getDataOrNull(
      queryLatestAvailableVersion.data?.quoteTemplateLatestAvailableVersion
    )?.quoteTemplate?.rootItem?.props2 ?? []
  ).filter(isNonComputedProp);
  const templateLatestAvailableVersionRootType = getDataOrNull(
    queryLatestAvailableVersion.data?.quoteTemplateLatestAvailableVersion
  )?.quoteTemplate?.rootItem?.type;

  const recommendedTemplateRootProps = (
    getDataOrNull(query.data?.quoteTemplateVersion)?.quoteTemplate?.rootItem
      ?.props2 ?? []
  ).filter(isNonComputedProp);
  const sourceProps = getDataOrNull(query.data?.item)?.props2 ?? [];

  const getPropertyOption = (prop: Props2) => ({
    value: prop.key,
    label: getEnhancedPropertyLabel(prop, false),
  });

  const recommendedTemplateRootPropsOptions =
    recommendedTemplateRootProps.map(getPropertyOption);

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const sourcePropsOptions = (recommendedPropKey: string) => {
    const recommendedProp = recommendedTemplateRootProps.find(
      p => p.key === recommendedPropKey
    );
    if (!recommendedProp) return [];

    const propType = getPropertyType(recommendedProp);
    const propIsArray = isArrayType(recommendedProp);
    switch (propType) {
      case "BOOLEAN":
        return sourceProps.filter(isBooleanType).map(getPropertyOption);
      case "TEXT":
        return sourceProps
          .filter(isTextType)
          .filter(propIsArray ? isArrayType : isNotArrayType)
          .map(getPropertyOption);
      case "NUMBER":
        return sourceProps
          .filter(isNumberType)
          .filter(propIsArray ? isArrayType : isNotArrayType)
          .map(getPropertyOption);
    }
  };

  const { expressionCheckResults, validateExpressions } =
    useExpressionValidation(docId, itemId);
  const eligibleIfExprError = expressionCheckResults?.find(
    value =>
      value.__typename ===
        "CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf" &&
      value.itemRecommendationId === recommendation.id
  );
  const includeIfExprError = expressionCheckResults?.find(
    value =>
      value.__typename ===
        "CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf" &&
      value.itemRecommendationId === recommendation.id
  );

  const initialValues: FormValues = {
    bindProps: recommendedTemplateConfig.bindProps,
    includeIfExpr: {
      expression: recommendationConfig.includeIfExpr,
      cursorIndex: null,
    },
    eligibleIfExpr: {
      expression: recommendationConfig.eligibleIfExpr,
      cursorIndex: null,
    },
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    includeIfExpr: Yup.object().required().shape({
      expression: Yup.string(),
      cursorIndex: Yup.number().nullable(),
    }),
    eligibleIfExpr: Yup.object().required().shape({
      expression: Yup.string(),
      cursorIndex: Yup.number().nullable(),
    }),
    bindProps: Yup.array().of(
      Yup.object().shape({
        destKey: Yup.string()
          .required()
          .label(
            t("Recommended template property", {
              ns: "QuoteItem",
            })
          )
          .test(
            "unique",
            t("Value must be unique", {
              ns: "Global",
            }),
            function (value: string | undefined) {
              // @ts-ignore
              const bindProps = (this.from as any)[1].value.bindProps as {
                destKey: string;
              }[];
              const sameValues = bindProps.filter(p => p.destKey === value);
              return sameValues.length <= 1;
            }
          ),
        sourceKey: Yup.string()
          .required()
          .label(
            t("Source item property", {
              ns: "QuoteItem",
            })
          ),
      })
    ),
  });

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        const result = await validateExpressions({
          docId,
          itemId,
          overrides: [
            {
              itemId,
              recommended: {
                eligibleIf: values.eligibleIfExpr.expression,
                includeIf: values.includeIfExpr.expression,
                recommendationId: recommendation.id,
              },
            },
          ],
        });

        const expressionCheckResult =
          result.data?.compileDocIsolatedExpression.results.filter(
            result =>
              (result.__typename ===
                "CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf" ||
                result.__typename ===
                  "CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf") &&
              result.itemRecommendationId === recommendation.id
          );

        if (expressionCheckResult.length > 0) {
          console.error("compile document error", expressionCheckResult);
          expressionCheckResult.forEach(result => {
            enqueueSnackbar(`Expression is invalid: ${result.messageText}`, {
              variant: "error",
            });
          });
          return;
        }

        await handleComplete({
          templateVersionNumber:
            recommendedTemplateConfig.templateVersionNumber,
          bindProps: values.bindProps.map(p => ({
            destKey: p.destKey,
            sourceKey: p.sourceKey,
          })),
          includeIfExpr: values.includeIfExpr.expression,
          eligibleIfExpr: values.eligibleIfExpr.expression,
        });
        handleClose();
      }}
    >
      {formikProps => (
        <Modal
          maxWidth="md"
          title={t("Configure template {templateName}", {
            ns: "QuoteItem",
            templateName: recommendation.teaserTitle,
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Remove template", { ns: "QuoteItem" }),
              handleClick: async () => {
                if (isDeleting) return;
                const result = await startConfirmProcess({
                  title: t("Confirm deletion", { ns: "Global" }),
                  text: t(
                    "Are you sure you would like to delete this recommended template?",
                    {
                      ns: "QuoteItem",
                    }
                  ),
                  confirmButtonLabel: t("Delete", { ns: "Global" }),
                });
                if (!result) return;
                setIsDeleting(true);
                try {
                  await handleComplete(null);
                } finally {
                  setIsDeleting(false);
                }
                handleClose();
              },
              buttonProps: {
                variant: "outlined",
                disabled: formikProps.isSubmitting || isDeleting,
              },
            },
            {
              label: t("Save", { ns: "Global" }),
              buttonProps: {
                form: formId,
                type: "submit",
                loading: formikProps.isSubmitting,
                disabled: !formikProps.isValid || !formikProps.dirty,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={2}>
              {templateLatestAvailableVersion &&
                templateLatestAvailableVersionRootType &&
                templateLatestAvailableVersion >
                  recommendedTemplateConfig.templateVersionNumber && (
                  <Box>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={async () => {
                        if (
                          !allowedItemTypes.includes(
                            templateLatestAvailableVersionRootType
                          )
                        ) {
                          enqueueSnackbar(
                            t(
                              "Root item type in the newer version is no longer compatible",
                              {
                                ns: "QuoteItem",
                              }
                            ),
                            { variant: "error" }
                          );
                          return;
                        }
                        if (isUpdating) return;
                        setIsUpdating(true);
                        try {
                          await handleComplete({
                            includeIfExpr:
                              formikProps.values.includeIfExpr.expression,
                            eligibleIfExpr:
                              formikProps.values.eligibleIfExpr.expression,
                            templateVersionNumber:
                              templateLatestAvailableVersion,
                            bindProps:
                              recommendedTemplateConfig.bindProps.filter(
                                ({ destKey }) =>
                                  templateLatestAvailableVersionRootProps.find(
                                    p => p.key === destKey
                                  )
                              ),
                          });
                          enqueueSnackbar(
                            t("Template was updated to the latest version", {
                              ns: "QuoteItem",
                            })
                          );
                        } finally {
                          setIsUpdating(false);
                        }
                      }}
                      loading={isUpdating}
                    >
                      {t("Update to latest version", { ns: "QuoteItem" })}
                    </LoadingButton>
                  </Box>
                )}
              <CollapseSection
                title={t("Rules", { ns: "QuoteItem" })}
                isInitiallyExpanded
              >
                <Stack direction="column" spacing={1}>
                  <ExpressionInputWithVariables
                    label={t("Eligible if", { ns: "QuoteItem" })}
                    placeholder={t("Formula", { ns: "QuoteItem" })}
                    itemId={itemId}
                    docId={docId}
                    projectId={null}
                    expressionError={
                      eligibleIfExprError
                        ? new Error(eligibleIfExprError.messageText)
                        : null
                    }
                    inputState={formikProps.values.eligibleIfExpr}
                    onInputStateChange={expr => {
                      formikProps.setFieldValue("eligibleIfExpr", expr);
                    }}
                    autoFocus={false}
                    direction="row"
                    allowedSources={EXPRESSION_SOURCES}
                  />
                  <ExpressionInputWithVariables
                    label={t("Include if", { ns: "QuoteItem" })}
                    placeholder={t("Formula", { ns: "QuoteItem" })}
                    itemId={itemId}
                    docId={docId}
                    projectId={null}
                    expressionError={
                      includeIfExprError
                        ? new Error(includeIfExprError.messageText)
                        : null
                    }
                    inputState={formikProps.values.includeIfExpr}
                    onInputStateChange={expr => {
                      formikProps.setFieldValue("includeIfExpr", expr);
                    }}
                    autoFocus={false}
                    direction="row"
                    allowedSources={EXPRESSION_SOURCES}
                  />
                </Stack>
              </CollapseSection>
              <CollapseSection
                title={t("Linked properties", { ns: "QuoteItem" })}
                isInitiallyExpanded
                disableAutoExpand
                itemCount={formikProps.values.bindProps.length}
              >
                <FieldArray
                  name="bindProps"
                  render={arrayHelpers => (
                    <Stack direction="column" spacing={1}>
                      {formikProps.values.bindProps.map((bindProp, index) => (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Box flex={1}>
                            <SelectField
                              name={`bindProps[${index}].destKey`}
                              required
                              disabled={formikProps.isSubmitting}
                              options={recommendedTemplateRootPropsOptions}
                              label={t("Recommended template property", {
                                ns: "QuoteItem",
                              })}
                              placeholder={t("Select property", {
                                ns: "QuoteItem",
                              })}
                              onChange={e => {
                                const propKey = e.target.value;
                                arrayHelpers.replace(index, {
                                  destKey: propKey,
                                  sourceKey: "",
                                });
                              }}
                            />
                          </Box>
                          <Box flex={1}>
                            <SelectField
                              name={`${arrayHelpers.name}[${index}].sourceKey`}
                              required
                              disabled={formikProps.isSubmitting}
                              options={
                                formikProps.values.bindProps[index].destKey
                                  ? sourcePropsOptions(
                                      formikProps.values.bindProps[index]
                                        .destKey
                                    )
                                  : []
                              }
                              label={t("Source item property", {
                                ns: "QuoteItem",
                              })}
                              placeholder={
                                formikProps.values.bindProps[index].destKey
                                  ? t("Select property", {
                                      ns: "QuoteItem",
                                    })
                                  : t("Select recommended property first", {
                                      ns: "QuoteItem",
                                    })
                              }
                              onChange={e => {
                                const propKey = e.target.value;
                                arrayHelpers.replace(index, {
                                  destKey:
                                    formikProps.values.bindProps[index].destKey,
                                  sourceKey: propKey,
                                });
                              }}
                            />
                          </Box>
                          <Box flex={0}>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </Box>
                        </Stack>
                      ))}
                      <Box>
                        <Button
                          color="secondary"
                          variant="text"
                          size="extra-small"
                          onClick={() => {
                            arrayHelpers.push({
                              destKey: "",
                              sourceKey: "",
                            });
                          }}
                          startIcon={<AddIcon />}
                        >
                          {t("Add property mapping", { ns: "QuoteItem" })}
                        </Button>
                      </Box>
                    </Stack>
                  )}
                />
              </CollapseSection>
            </Stack>
          </Form>
          <ConfirmProcess ref={confirmProcessRef} />
        </Modal>
      )}
    </Formik>
  );
};
