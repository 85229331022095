/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateRequirementPdfQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  requirementId: Types.Scalars['ID']['input'];
}>;


export type GenerateRequirementPdfQuery = { requirementGeneratePdf: { __typename: 'MissingPermissions' } | { __typename: 'RequirementGeneratePdfResult', url: string } };


export const GenerateRequirementPdfDocument = gql`
    query GenerateRequirementPdf($projectId: ID!, $requirementId: ID!) {
  requirementGeneratePdf(projectId: $projectId, requirementId: $requirementId) {
    ... on RequirementGeneratePdfResult {
      url
    }
  }
}
    `;

/**
 * __useGenerateRequirementPdfQuery__
 *
 * To run a query within a React component, call `useGenerateRequirementPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateRequirementPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateRequirementPdfQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      requirementId: // value for 'requirementId'
 *   },
 * });
 */
export function useGenerateRequirementPdfQuery(baseOptions: Apollo.QueryHookOptions<GenerateRequirementPdfQuery, GenerateRequirementPdfQueryVariables> & ({ variables: GenerateRequirementPdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateRequirementPdfQuery, GenerateRequirementPdfQueryVariables>(GenerateRequirementPdfDocument, options);
      }
export function useGenerateRequirementPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateRequirementPdfQuery, GenerateRequirementPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateRequirementPdfQuery, GenerateRequirementPdfQueryVariables>(GenerateRequirementPdfDocument, options);
        }
export function useGenerateRequirementPdfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GenerateRequirementPdfQuery, GenerateRequirementPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateRequirementPdfQuery, GenerateRequirementPdfQueryVariables>(GenerateRequirementPdfDocument, options);
        }
export type GenerateRequirementPdfQueryHookResult = ReturnType<typeof useGenerateRequirementPdfQuery>;
export type GenerateRequirementPdfLazyQueryHookResult = ReturnType<typeof useGenerateRequirementPdfLazyQuery>;
export type GenerateRequirementPdfSuspenseQueryHookResult = ReturnType<typeof useGenerateRequirementPdfSuspenseQuery>;
export type GenerateRequirementPdfQueryResult = Apollo.QueryResult<GenerateRequirementPdfQuery, GenerateRequirementPdfQueryVariables>;