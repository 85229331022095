import { StyledComponent } from "@emotion/styled";
import { ellipsisStyle, noWrapStyle } from "@msys/ui";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import BusinessIcon from "@mui/icons-material/Business";
import DescriptionIcon from "@mui/icons-material/Description";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import FlagIcon from "@mui/icons-material/Flag";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TodayIcon from "@mui/icons-material/Today";
import {
  Stack,
  Theme,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ReactComponent as CraftsmanIcon } from "../assets/icons/icon-craftsman.svg";

export const LocationLine = ({ children }: React.PropsWithChildren<{}>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color="textSecondary"
  >
    <Stack direction="row" alignItems="center" spacing={1 / 4}>
      <PlaceIcon style={{ fontSize: "16px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const CompanyLine = ({ children }: React.PropsWithChildren<{}>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color="textSecondary"
  >
    <Stack direction="row" alignItems="center" spacing={1 / 4}>
      <BusinessIcon style={{ fontSize: "14px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const ContactLine = ({ children }: React.PropsWithChildren<{}>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color="textSecondary"
  >
    <Stack direction="row" alignItems="center" spacing={1 / 4}>
      <PersonIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const EmailLine = ({ children }: React.PropsWithChildren<{}>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color="textSecondary"
  >
    <Stack direction="row" alignItems="center" spacing={1 / 4}>
      <AlternateEmailIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const PhoneLine = ({ children }: React.PropsWithChildren<{}>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color="textSecondary"
  >
    <Stack direction="row" alignItems="center" spacing={1 / 4}>
      <PhoneIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const ClientLine = ({ children }: React.PropsWithChildren<{}>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color="textSecondary"
  >
    <Stack direction="row" alignItems="center" spacing={1 / 4}>
      <PersonPinIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const MasterLine = ({
  dark = false,
  children,
}: React.PropsWithChildren<{ dark?: boolean }>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color={dark ? "textPrimary" : "textSecondary"}
  >
    <Stack direction="row" alignItems="center" spacing={0}>
      <CraftsmanIcon style={{ height: "14px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const SupplierLine = ({ children }: React.PropsWithChildren<{}>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color="textSecondary"
  >
    <Stack direction="row" alignItems="center" spacing={1 / 2}>
      <LocalShippingIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const RangeLine = ({ children }: React.PropsWithChildren<{}>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color="textSecondary"
  >
    <Stack direction="row" alignItems="center" spacing={1 / 2}>
      <ScheduleIcon style={{ fontSize: "14px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const CreatedDateLine = ({
  children,
  color = "textSecondary",
}: React.PropsWithChildren<{ color?: TypographyProps["color"] }>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color={color}
  >
    <Stack direction="row" alignItems="center" spacing={1 / 2}>
      <TodayIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const DeadlineDateLine = ({
  overdue = false,
  children,
}: React.PropsWithChildren<{ overdue?: boolean }>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color={overdue ? "error" : "secondary"}
  >
    <Stack direction="row" alignItems="center" spacing={1 / 4}>
      <AvTimerIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const EarliestPlanSessionsDateLine = ({
  overdue = false,
  children,
}: React.PropsWithChildren<{ overdue?: boolean }>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color={overdue ? "error" : "secondary"}
  >
    <Stack direction="row" alignItems="center" spacing={1 / 4}>
      <EventAvailableIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const EarliestStartDateLine = ({
  children,
  color = "textSecondary",
}: React.PropsWithChildren<{ color?: TypographyProps["color"] }>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color={color}
  >
    <Stack direction="row" alignItems="center" spacing={1 / 2}>
      <FlagIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const DocumentLine = ({
  children,
  color = "textSecondary",
}: React.PropsWithChildren<{ color?: TypographyProps["color"] }>) => (
  <Typography
    variant="caption"
    component="div"
    style={ellipsisStyle}
    color={color}
  >
    <Stack direction="row" alignItems="center" spacing={1 / 2}>
      <DescriptionIcon style={{ fontSize: "15px", flexShrink: 0 }} />
      <div style={ellipsisStyle}>{children}</div>
    </Stack>
  </Typography>
);

export const ActionLine = ({ children }: React.PropsWithChildren<{}>) => (
  <div
    style={{
      height: "22px",
      marginTop: "-4px",
      marginRight: "-4px",
      display: "flex",
      flexDirection: "row",
      gap: "4px",
    }}
  >
    {children}
  </div>
);

const Wrapper = styled("div")(`
  width: 100%;
`);

const IconWrapper = styled("div")(`
  height: 21px;
  width: 21px;
  position: relative;
  left: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
`);

export interface DataItemProps {
  /**
   * Top-left icon on the card
   */
  icon?: React.ReactNode;
  /**
   * Main status
   */
  status?: React.ReactNode;
  /**
   * Label next to date/deadline
   */
  label?: React.ReactNode;
  /**
   * Created date line
   */
  createdDate?: React.ReactNode;
  /**
   * Earliest plan session date line
   */
  earliestPlanSessionDate?: React.ReactNode;
  /**
   * Due date line
   */
  dueDate?: React.ReactNode;
  /**
   * Date as the deadline
   */
  deadlineDate?: React.ReactNode;
  /**
   * Date as the earliest start day
   */
  earliestStartDate?: React.ReactNode;
  /**
   * Document dates range line
   */
  documentDatesRange?: React.ReactNode;
  /**
   * Planned sessions range
   */
  planSessionsRange?: React.ReactNode;
  /**
   * Whether date in overdue
   */
  overdue?: boolean;
  /**
   * Main title
   */
  title: React.ReactNode;
  /**
   * Location at the bottom-left
   */
  location?: React.ReactNode;
  /**
   * Price line
   */
  price?: React.ReactNode;
  /**
   * Master line
   */
  master?: React.ReactNode;
  /**
   * Supplier line
   */
  supplier?: React.ReactNode;
  /**
   * Right button
   */
  button?: React.ReactNode;
  /**
   * Badge counter
   */
  counter?: number;
  /**
   * Whether title should be restricted (ellipsis)
   */
  restricted?: boolean;
}

export const DataItem = ({
  restricted = true,
  overdue = false,
  icon,
  status,
  label,
  createdDate,
  earliestStartDate,
  earliestPlanSessionDate,
  deadlineDate,
  dueDate,
  documentDatesRange,
  planSessionsRange,
  title,
  location,
  price,
  master,
  supplier,
  button,
  counter,
}: DataItemProps) => {
  const { t } = useTranslate("DataItem");
  return (
    <Wrapper>
      {(status ||
        icon ||
        documentDatesRange ||
        planSessionsRange ||
        createdDate ||
        dueDate ||
        deadlineDate) && (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            {status}
            {icon && <IconWrapper>{icon}</IconWrapper>}
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
            style={ellipsisStyle}
          >
            {label && (
              <div style={{ ...ellipsisStyle, flexShrink: 0, flexGrow: 0 }}>
                {label}
              </div>
            )}
            {documentDatesRange && (
              <Tooltip title={t("Document dates")} arrow placement="top">
                <div>
                  <RangeLine>{documentDatesRange}</RangeLine>
                </div>
              </Tooltip>
            )}
            {planSessionsRange && (
              <Tooltip title={t("Planned session")} arrow placement="top">
                <div>
                  <RangeLine>{planSessionsRange}</RangeLine>
                </div>
              </Tooltip>
            )}
            {createdDate && (
              <Tooltip title={t("Created date")} arrow placement="top">
                <div>
                  <CreatedDateLine>{createdDate}</CreatedDateLine>
                </div>
              </Tooltip>
            )}
            {earliestPlanSessionDate && (
              <Tooltip
                title={t("Earliest planned work session")}
                arrow
                placement="top"
              >
                <div>
                  <EarliestPlanSessionsDateLine>
                    {earliestPlanSessionDate}
                  </EarliestPlanSessionsDateLine>
                </div>
              </Tooltip>
            )}
            {earliestStartDate && (
              <Tooltip title={t("Earliest start")} arrow placement="top">
                <div>
                  <EarliestStartDateLine>
                    {earliestStartDate}
                  </EarliestStartDateLine>
                </div>
              </Tooltip>
            )}
            {dueDate && (
              <Tooltip title={t("Due date")} arrow placement="top">
                <div>
                  <DeadlineDateLine overdue={overdue}>
                    {dueDate}
                  </DeadlineDateLine>
                </div>
              </Tooltip>
            )}
            {deadlineDate && (
              <Tooltip title={t("Deadline")} arrow placement="top">
                <div>
                  <DeadlineDateLine overdue={overdue}>
                    {deadlineDate}
                  </DeadlineDateLine>
                </div>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      )}
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="body1"
          component="div"
          style={restricted ? ellipsisStyle : undefined}
        >
          {title}
        </Typography>
        {(typeof counter === "number" && counter > 0) || button ? (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
            style={ellipsisStyle}
            flexShrink={0}
            flexGrow={0}
          >
            {button ? (
              <span
                onMouseDown={e => {
                  e.stopPropagation();
                }}
                onTouchStart={e => {
                  e.stopPropagation();
                }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {button}
              </span>
            ) : null}
            {typeof counter === "number" && counter > 0 && (
              <Counter>{counter}</Counter>
            )}
          </Stack>
        ) : null}
      </Stack>
      {(location || price || supplier || master) && (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          style={ellipsisStyle}
          flexShrink={1}
          flexGrow={1}
        >
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
            style={ellipsisStyle}
            flexShrink={1}
            flexGrow={1}
          >
            {location && (
              <Tooltip title={t("Location")} arrow placement="bottom">
                <LocationLine>{location}</LocationLine>
              </Tooltip>
            )}
            {supplier && <SupplierLine>{supplier}</SupplierLine>}
          </Stack>
          <Stack
            justifyContent="flex-end"
            alignItems="center"
            flexShrink={0}
            flexGrow={0}
            direction="row"
            spacing={1}
          >
            {master && (
              <Tooltip title={t("Assigned workers")} arrow placement="bottom">
                <div>
                  <MasterLine>{master}</MasterLine>
                </div>
              </Tooltip>
            )}
            {price && (
              <Tooltip title={t("Price")} arrow placement="bottom">
                <Typography
                  variant="caption"
                  component="div"
                  style={noWrapStyle}
                >
                  {price}
                </Typography>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      )}
    </Wrapper>
  );
};

export const Counter: StyledComponent<
  MUIStyledCommonProps<Theme> & { children?: React.ReactNode }
> = styled("div")(
  ({ theme }) => `
  background-color: ${theme.palette.error.main};
  color: ${theme.palette.common.white};

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;

  height: 20px;
  min-width: 20px;

  border-radius: 10px;

  padding: 0 6px;

  box-sizing: border-box;

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
`
);
