import {
  PimSearchProductTypesSortingInput,
  TemplateListsSorting,
} from "../../../../clients/graphqlTypes";
import { useFiltersAndPagination } from "../../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../../commons/hooks/useCollectionView";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../../constants";

export const DEFAULT_SORTING: PimSearchProductTypesSortingInput[] = [
  { column: "label", direction: "asc" },
];

interface BaseQueryVariables {
  searchTerm?: string | null;
  offset?: number | null;
  limit: number;
}

export const useProductTypesList = <QueryVariables extends BaseQueryVariables>({
  activeViewStorageKey,
  activeViewInitialDesktopValue,
  activeViewInitialMobileValue,
  initialFilters,
  pageUrlParam,
  perPageUrlParam,
  filtersUrlParam,
  sortingsUrlParam,
}: {
  activeViewStorageKey: string;
  activeViewInitialDesktopValue: CollectionView;
  activeViewInitialMobileValue: CollectionView;
  initialFilters: Omit<QueryVariables, "offset" | "limit" | "sorting">;
  pageUrlParam?: string;
  perPageUrlParam?: string;
  filtersUrlParam?: string;
  sortingsUrlParam?: string;
}) => {
  const {
    limit,
    offset,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<
    PimSearchProductTypesSortingInput,
    Omit<QueryVariables, "offset" | "limit" | "sorting">
  >(
    initialFilters,
    [],
    PAGE_LIST_RESULTS_PER_PAGE,
    pageUrlParam,
    perPageUrlParam,
    filtersUrlParam,
    sortingsUrlParam
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    activeViewStorageKey,
    activeViewInitialDesktopValue,
    activeViewInitialMobileValue
  );

  return {
    limit,
    offset,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    activeView,
    setActiveView,
    queryBaseVariables: QUERY_BASE_VARIABLES,
    toRemoveParams,
  };
};
